import React from 'react';
import './CollectionOptions.css'; 

const CollectionOptions = (props) => {



  const handleCollectionClick = (collectionName) => {
    props.actions.handleCollectionSelection(collectionName);
  };

  if (!props.actionProvider.options || !props.actionProvider.options === 0) {
    return <div className="collection-options-container">No collections available.</div>;
  }


  const fetchedOptions = props.actionProvider?.options;

  return (
    <div className="collection-options-container"> 
      {fetchedOptions.map((option, index) => (
        <div key={index} className="collection-box" onClick={() => handleCollectionClick(option.db_collection)}>
          <p>{option.db_collection}</p>
        </div>
      ))}
    </div>
  );
};

export default CollectionOptions;
