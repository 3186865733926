import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter} from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from 'react-redux';
import "./index.css";
import App from "./App";
import store from "./store";



const root = ReactDOM.createRoot(document.getElementById("root"));




root.render(
  <Provider store={store}>
  <BrowserRouter>
    <Auth0Provider
      domain="minsky.uk.auth0.com"
      clientId="c7vmB7v7gXQ0ciSnEUrVqt6fiYAGmSwX"
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <App />
    </Auth0Provider>
  </BrowserRouter>
  </Provider>
);
