
const ENV_PROD = false; // For proudction make it true, don't change in the local environment
let minsky_url = "";

if (ENV_PROD) {
  minsky_url = ""

} else {
  minsky_url = "https://uatdj.neurobridge.tech/"

}


const API_LIVE = {
  BASE_URL_MINSKY: minsky_url,
  
  //----------------------------------------------------------
  getUserInfoandToken:`${minsky_url}minsky/login`,

  //------------------------- < User Role >  -------------------------------
  getUserRoleAndItsOrg:`${minsky_url}minsky/user/roles/`,

  //------------------------- < ASISSTANTS >  -------------------------------
  asistantCreate:`${minsky_url}minsky/assistant/register/`, 
  asistantUserQuery:`${minsky_url}minsky/docs/questions-answers/`,
  getAssistants:`${minsky_url}minsky/assistant/fetch/`,
  getAssistantChats:`${minsky_url}minsky/assistant/chat/`,
  getAssistantChatHistory:`${minsky_url}/minsky/assistant/chat/history/`,
  getUserAssistants:`${minsky_url}minsky/user/assistant/`, // Fetch User Assistants 
  updatePromptTemplate:`${minsky_url}minsky/assistant/update-prompt/`, //Edit Prompt Template of Assistant 


  //-------------------------- < Organisation > --------------------------
  createOrganisation:`${minsky_url}minsky/organisation/create/`,
  getOrganisations:`${minsky_url}minsky/organisation/detail/`,
  roleTypes:`${minsky_url}/minsky/organisation/roles/`,

  

  //-------------------------- < Upload Data > --------------------------
  getCollectionInfo:`${minsky_url}minsky/vector/collection/`,
  getChunkDetails:`${minsky_url}minsky/vector/chunk/`,
  creatingCollection:`${minsky_url}minsky/vector/create-collection/`,
  uploadFile:`${minsky_url}minsky/docs/upload/pdf/`,
  getFileRecords:`${minsky_url}minsky/docs/status/`,
  uploadURLs:`${minsky_url}minsky/docs/upload/url/`,


   //-------------------------- < Manage Team > --------------------------
  inviteMember:`${minsky_url}/minsky/organisation/send-invitation/`,
  getOrgMembers:`${minsky_url}minsky/organisation/members/`,

  //-------------------------- < Search Bar Widget Preview > --------------------------
  getUserQueryResponse:`${minsky_url}/minsky/demo/genai-search/`,
  getUserQueryResponseChatbot:`${minsky_url}/minsky/demo/genai-search/`,

 
};

const MINSKY_URL = API_LIVE;

export default MINSKY_URL;


