import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector} from "react-redux";
import * as Yup from "yup";
import { Button,Input } from "antd";


const { TextArea } = Input;

const UploadUrl = ({ handleSubmit })  => {

    const urlLoader = useSelector(
        (state) => state.uploadData.urlLoading
      );


      const validationSchema = Yup.object().shape({
        urls: Yup.string()
          .required("Please enter at least one URL.")
          .matches(
            /^(https?:\/\/[^\s/$.?#]+\.[^\s]*)(\s+https?:\/\/[^\s/$.?#]+\.[^\s]*)*$/,
            "Please enter valid URL(s)"
          ),
      });
      
      


  const initialValues = {
    urls: "",
  };

 


  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
        e.preventDefault(); 
        const textarea = e.target;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const value = textarea.value;
       
        textarea.value = value.substring(0, start) + "\n" + value.substring(end);
        textarea.setSelectionRange(start + 1, start + 1); 
    }
};


  return (
    <div>
      <div style={{ marginTop: "50px" }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting,  errors, touched }) => (
            <Form>
              <Field
                as={TextArea}
                name="urls"
                placeholder="Enter URL per line"
                autoSize={{ minRows: 6, maxRows: 10 }}
                style={{
                  borderColor: errors.urls && touched.urls ? "red" : "",
                }}

                onKeyDown={handleKeyDown}
              />
              <ErrorMessage
                name="urls"
                component="div"
                style={{ fontSize: "15px", color: "red" }}
              />

              <div style={{ marginTop: "16px", textAlign: "center" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={urlLoader}
                  style={{ width: "200px" }}
                >
                  Next
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UploadUrl;
