import { createChatBotMessage } from 'react-chatbot-kit';
import MessageParser from './MessageParser';
import ActionProvider from './ActionProvider';
import CollectionOptions from './CollectionOptions';

const config = {
  botName: "Minksy",
  headerTitle: "Minksy", 
  initialMessages: [createChatBotMessage("Hello, I am Minsky. How may I help you today?")],
  customComponents: {
    MessageParser: (props) => <MessageParser {...props} />,
    ActionProvider: (props) => <ActionProvider {...props} />,
  },
  widgets: [
    {
      widgetName: 'collectionOptions', 
      widgetFunc: (props) => <CollectionOptions {...props} />, 
    },
  ],
};

export default config;
