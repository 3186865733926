import React from 'react';
import { Link } from 'react-router-dom';
import './ErrorPage.css'; 
// import { useAuth0 } from "@auth0/auth0-react";


const ErrorPage = () => {

  // const { isAuthenticated} = useAuth0();
  // console.log(isAuthenticated)
  return (
    <div className="error-container" style={{color:"black"}}>
      <h1 className="error-title">404</h1>
      <h4 className="error-message">Page not found</h4>
      <p className="error-message">Oops! The page you are looking for does not exist. It might have been moved or deleted.</p>
      <Link className="back-to-home" to="/dashboard">Back To Home</Link>
    </div>
  );
};

export default ErrorPage;
