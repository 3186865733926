import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosJWTInstance } from "../Utilities/axiosInstance";
import MINSKY_URL from "../config";

const initialState = {
  createAssist: {
    isLoading: false,
    prompt_template:'',
    assistant_id:''
  },
  assistantQuery: {
    isLoading: false,
    answer: "",
    chat:"",
  },
  loadingMap: {}, // loadingMap to track loading state for each message
  assistants: [],
  userAssistants: [],
  assistantChats: [],
  assistantChatHistory: [],
  assistantIdForDisplayingChat: null,
  storedChatIdAfterChatClick:null
};

export const createAssistant = createAsyncThunk(
  "assistantCreate/createAssistant",
  async (requestBody) => {
    const response = await axiosJWTInstance
      .post(`${MINSKY_URL.asistantCreate}`, requestBody)
      .catch((error) => {
        return error.response;
      });

    if (response.status === 400) {
      return Promise.reject(response.data.error.assistant_name[0]);
    }
    return response.data;
  }
);



export const updateAssistantPrompt = createAsyncThunk(
  "prompttemplate/updateAssistantPrompt",
  async (requestBody) => {
    const response = await axiosJWTInstance
      .post(`${MINSKY_URL.updatePromptTemplate}`, requestBody)
      .catch((error) => {
        return error.response;
      });

    if (response.status === 400) {
      return Promise.reject(response.data.error.assistant_name[0]);
    }
    return response.data;
  }
);

export const assistantQuery = createAsyncThunk(
  "query/assistantQuery",
  async (requestBody) => {
    const response = await axiosJWTInstance
      .post(`${MINSKY_URL.asistantUserQuery}`, requestBody)
      .catch((error) => {
        return error.response;
      });

    return response.data;
  }
);

export const fetchAssistants = createAsyncThunk(
  "totalAssistants/fetchAssistants",
  async ({ organisationId }, { rejectWithValue }) => {
    try {
      if (organisationId !== null && organisationId !== undefined) {
        const response = await axiosJWTInstance.get(
          `${MINSKY_URL.getAssistants}${organisationId}/`
        );
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


//------------------> User Assistants <-----------------
export const fetchUserAssistants = createAsyncThunk(
  "allAssistants/fetchUserAssistants",
  async ({ organisationId }, { rejectWithValue }) => {
    try {
      const response = await axiosJWTInstance.get(
        `${MINSKY_URL.getUserAssistants}${organisationId}/`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAssistantChats = createAsyncThunk(
  "assistantChats/getAssistantChats",
  async ({ assistantId }, { rejectWithValue }) => {
    try {
      const response = await axiosJWTInstance.get(
        `${MINSKY_URL.getAssistantChats}${assistantId}/`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const fetchAssistantChatHistory = createAsyncThunk(
  "assistantChatHistory/fetchAssistantChatHistory",
  async ({ assistantChatId }, { rejectWithValue }) => {
    try {
      const response = await axiosJWTInstance.get(
        `${MINSKY_URL.getAssistantChatHistory}${assistantChatId}/`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const assistantSlice = createSlice({
  name: "assistant",
  initialState,
  reducers: {
    resetPromptTemplate: (state) => {
      state.createAssist.prompt_template = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAssistant.pending, (state, action) => {
        state.createAssist.isLoading = true;
      })
      .addCase(createAssistant.fulfilled, (state, action) => {
        state.createAssist.isLoading = false;
        const { prompt_template, assistant_id } = action.payload;
        state.createAssist = {
          ...state.createAssist,
          prompt_template,
          assistant_id,
        };
      })

      
      .addCase(createAssistant.rejected, (state, action) => {
        state.createAssist.isLoading = false;
      })

      //--------------------------------------------------------------------------------


      .addCase(updateAssistantPrompt.pending, (state, action) => {

      })
      .addCase(updateAssistantPrompt.fulfilled, (state, action) => {
      

      })

      
      .addCase(updateAssistantPrompt.rejected, (state, action) => {
        
      })

       //--------------------------------------------------------------------------------


      .addCase(assistantQuery.pending, (state, action) => {
        // Seting loading state for the specific message in loadingMap
        // state.loadingMap[action.meta.requestId] = true;
      })
        .addCase(assistantQuery.fulfilled, (state, action) => {
          state.assistantQuery.answer = action.payload.answer;
          state.assistantQuery.chat = action.payload.chat;
          localStorage.setItem("ChatId",state.assistantQuery.chat)

      })
      
        // delete state.loadingMap[action.meta.requestId];
  
      .addCase(assistantQuery.rejected, (state, action) => {
        // delete state.loadingMap[action.meta.requestId];
      })

      //-----------------------------------------------------------------

      .addCase(fetchAssistants.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchAssistants.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          const { data } = action.payload;
          state.assistants = data.map((assistant) => ({
            assistant_id: assistant.assistant_id,
            assistant_name: assistant.assistant_name,
          }));
        } else {
          // console.error("Error: Response data is undefined for fetchAssistants");
        }
      
      
      })
      .addCase(fetchAssistants.rejected, (state, action) => {
        state.status = "failed";
      })

      //------------------------------------------------------------


      .addCase(fetchUserAssistants.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchUserAssistants.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          const { data } = action.payload;
          state.userAssistants = data.map((assistant) => ({
            assistant_id: assistant.assistant_id,
            assistant_name: assistant.assistant_name,
          }));
        } else {
          // console.error("Error: Response data is undefined for fetchUserAssistants");
        }
      })
    
      .addCase(fetchUserAssistants.rejected, (state, action) => {
        state.status = "failed";
      })

         //------------------------------------------------------------


      .addCase(fetchAssistantChats.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchAssistantChats.fulfilled, (state, action) => {
        const { assistant, data } = action.payload;
        state.assistantIdForDisplayingChat = assistant;
        state.assistantChats = data.map((chat) => ({
          id: chat.chat_id,
          name: chat.chat_name,
        }));
      })
      .addCase(fetchAssistantChats.rejected, (state, action) => {
        state.status = "failed";
      })

      //--------------------------------------------------------------------------
      .addCase(fetchAssistantChatHistory.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchAssistantChatHistory.fulfilled, (state, action) => {
        const { data , chat_id} = action.payload;
        state.storedChatIdAfterChatClick = chat_id;
        state.assistantChatHistory = data.map((chat) => ({
          id: chat.id,
          chat: chat.chat_data,
          role:chat.role
        }));
      })
      .addCase(fetchAssistantChatHistory.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});


export const { resetPromptTemplate } = assistantSlice.actions;
export const assistantReducer = assistantSlice.reducer;
