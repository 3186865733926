import axios from "axios";
import { Spin } from 'antd';
import ReactDOM from "react-dom";



export const axiosInstance = axios.create();
export const axiosJWTInstance = axios.create();

export const reloadPageWithoutUserNotice = () => {

  const overlayContainer = document.createElement("div");
  overlayContainer.style.position = "fixed";
  overlayContainer.style.top = "0";
  overlayContainer.style.left = "0";
  overlayContainer.style.width = "100vw";
  overlayContainer.style.height = "100vh";
  overlayContainer.style.backgroundColor = "rgba(0, 0, 0, 0.8)"; 
  overlayContainer.style.display = "flex";
  overlayContainer.style.alignItems = "center";
  overlayContainer.style.justifyContent = "center";
  overlayContainer.style.zIndex = "9999";

  const spinnerElement = document.createElement("div");
  spinnerElement.className = "spinner"; 

  ReactDOM.render(<Spin size="large" />, spinnerElement);

  const spinnerText = document.createElement("div");
  spinnerText.textContent = "Please Wait...";
  spinnerText.style.color = "white"; 
  spinnerText.style.marginTop = "10px"; 
  spinnerElement.appendChild(spinnerText);

  overlayContainer.appendChild(spinnerElement);

  document.body.appendChild(overlayContainer);

  document.body.style.transition = "opacity 1.5s ease";
  document.body.style.opacity = 0;

  setTimeout(() => {
    window.location.reload();
  }, 300);
};



const getAccessToken = () => {
  return sessionStorage.getItem("access_token");
};



// Interceptor for axiosInstance
axiosInstance.interceptors.request.use(
  (config) => {
    const idToken = sessionStorage.getItem("idToken");

    if (idToken) {
      config.headers["Authentication"] = idToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor for axiosJWTInstance
axiosJWTInstance.interceptors.request.use(
  async (config) => {
    const idToken = sessionStorage.getItem("idToken");
    const accessToken = getAccessToken();




    // console.log(idToken,"ID tOKEN")
    // console.log(accessToken,"ID tOKEN")

    const timeDuration = 2000;

    setTimeout(() => {
      if (!idToken || !accessToken) {
  
      }
    }, timeDuration);

    if (idToken && accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
      config.headers["Authentication"] = idToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
