import React from "react";
import "./SplashScreen.css"; 


const SplashScreen = () => {
  return (
    <div className="splash-container">
      <h1 className="splash-text" style={{fontWeight:"bolder",fontSize:"60px"}}>Minsky</h1>
    </div>
  );
};

export default SplashScreen;
