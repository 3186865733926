import React, { useEffect } from "react";
import { Modal, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { chunkDetailsInfo, createCollection ,fetchCollectionsInfo } from "../../../../../slices/uploadDataSlice";



const initialValues = {
  folderName: "",
  selectedChunk: "",
};

const validationSchema = Yup.object().shape({
  folderName: Yup.string()
  .required('Please enter folder name')
  .matches(/^[^\s]+(?:_[^\s]+)*$/, 'Folder name cannot contain spaces and only "_" special character is allowed'),

  selectedChunk: Yup.string().required("Please select chunk"),
});

const CreateFolderModal = ({ visible, setModalVisible, onCancel }) => {
  const dispatch = useDispatch();
  const storedOrganisationId = localStorage.getItem("selectedOrgIdFromSplash");
  const chunkDetails = useSelector(
    (state) => state.uploadData.chunkDetailsInfo
  );

  useEffect(() => {
    dispatch(chunkDetailsInfo({ orgaisationId: storedOrganisationId }));
  }, [dispatch, storedOrganisationId]);




  const onSubmit = (values, { resetForm }) => {
    const storedOrganisationId = localStorage.getItem("selectedOrgIdFromSplash");
  
    const postData = {
      organisation: storedOrganisationId,
      collection: values.folderName,
      chunk: values.selectedChunk
    };
  
    dispatch(createCollection(postData)).then((resp) => {
      if (resp.meta.requestStatus === "fulfilled") {
        console.log(resp);
        message.success(resp?.payload?.message);
        setModalVisible(false);
        dispatch(fetchCollectionsInfo({ orgaisationId: storedOrganisationId }));
        resetForm();
      } else {
     
      }
    }).catch((error) => {
      
      console.error('Error:', error);
    });
  };

  

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleSelectChange = (value) => {
    formik.setFieldValue("selectedChunk", value);
  };


  return (
    <Modal
      visible={visible}
      title="Create Knowledge Base"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={formik.handleSubmit}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-3">
          <label htmlFor="folderName" className="form-label">
            Folder Name
          </label>
          <Input
            id="folderName"
            name="folderName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.folderName}
            className="form-control"
          />
          {formik.touched.folderName && formik.errors.folderName ? (
            <div className="text-danger">{formik.errors.folderName}</div>
          ) : null}
        </div>

        <div className="mb-3">
          <label htmlFor="selectedChunk" className="form-label">
             Chunk Size
          </label>
          <select
            id="selectedChunk"
            name="selectedChunk"
            onChange={(e) => handleSelectChange(e.target.value)}
            onBlur={formik.handleBlur}
            value={formik.values.selectedChunk}
            className={`form-select ${
              formik.touched.selectedChunk && formik.errors.selectedChunk
                ? "is-invalid"
                : ""
            }`}
          >
            <option value="" disabled>
              Select Chunk Size
            </option>
            {chunkDetails.map((chunk) => (
              <option key={chunk} value={chunk}>
                {chunk}
              </option>
            ))}
          </select>
          {formik.touched.selectedChunk && formik.errors.selectedChunk ? (
            <div className="invalid-feedback">
              {formik.errors.selectedChunk}
            </div>
          ) : null}

        </div>
      </form>
    </Modal>
  );
};

export default CreateFolderModal;
