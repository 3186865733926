import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AllRoutes from './AllRoutes';
import SplashScreen from './components/SplashScreen/SplashScreen';
import { useDispatch } from 'react-redux';
import { getUserRoleAndItsOrg} from './slices/authSlice';


const App = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();

  const [showSplash, setShowSplash] = useState(true);




  useEffect(() => {
    const setTokenAndFetchRoles = async () => {
      try {
        if (isAuthenticated) {
          const idTokenClaims = await getIdTokenClaims();
          const idToken = idTokenClaims.__raw;
          sessionStorage.setItem('idToken', idToken);
  
          const response = await dispatch(getUserRoleAndItsOrg({}));

  
          if (response.payload && response.payload.token) {
            const { access_token, expiry_in } = response.payload.token;
            sessionStorage.setItem("access_token", access_token);
            sessionStorage.setItem("expiry_in", expiry_in.toString());
          } else {
            console.error('Error: Invalid response structure from getUserRoles');
          }
        }
      } catch (error) {
        console.error('Error fetching user roles:', error);
      }
    };
  
    setTokenAndFetchRoles();
  }, [isAuthenticated, getIdTokenClaims, dispatch]);
  



  useEffect(() => {
    // Hide splash screen after 2 seconds
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="container">
      {(!isAuthenticated && !isLoading && showSplash) && <SplashScreen />} 
      <div className="content">
        {!showSplash && <AllRoutes />} 
      </div>
    </div>
  );
};

export default App;