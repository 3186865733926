import React from 'react'
import "./Loader.css"

const Loader = () => {
  return (
    <div className="loader">
    <div className="tile"></div>
    <div className="tile"></div>
    <div className="tile"></div>
    <div className="tile"></div>
    <div className="tile"></div>
  </div>
  )
}

export default Loader
