import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Dropdown, Space, Menu, Switch, Select} from "antd";
import {
  LogoutOutlined,
  DownOutlined,
  SettingOutlined,
  CheckOutlined,
  SlidersOutlined
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import "./Navbar.css";
import CreateOrganization from "./CreateOrganisationModal/CreateOrganisation";
import { fetchOrganisations } from "../../../slices/organisationSlice";
import { fetchAssistants } from "../../../slices/assistantSlice";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const { Option } = Select;

const Navbar = ({ darkMode }) => {
  const dispatch = useDispatch();
  const { user, logout } = useAuth0();

  const navigate = useNavigate();
  const [isOrganisationModalVisible, setIsOrganisationModalVisible] =
    useState(false);
  const [isDarkMode, setIsDarkMode] = useState(darkMode);
  const [selectedOption, setSelectedOption] = useState("");
  const isUser =
    localStorage.getItem("selectedOrgUserRolesFromSplash") === "user";

  const organisations = useSelector(
    (state) => state.organisation.organisationsFetch.organisations
  );
  const assistants = useSelector((state) => state.assistant.assistants);

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    logout({ returnTo: window.location.origin });
  };

  const combineNames = (givenName, familyName) => {
    return `${givenName} ${familyName}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchOrganisations({}));
      } catch (error) {
        console.error("Error fetching organisations:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const storedOrgId = localStorage.getItem("selectedOrgIdFromSplash");
    const storedOrgName = localStorage.getItem("selectedOrgNameFromSplash");

    if (storedOrgId && storedOrgName) {
      setSelectedOption(storedOrgName);
    } else {
      if (organisations && organisations.length > 0) {
        const defaultOption = organisations[0]?.name || "Create Organisation";
        setSelectedOption(defaultOption);
        localStorage.setItem(
          "selectedOrgIdFromSplash",
          organisations[0]?.organisation_id
        );
        localStorage.setItem("selectedOrgNameFromSplash", defaultOption);
      } else {
        setSelectedOption("Create Organisation");
      }
    }
  }, [organisations]);

  useEffect(() => {
    if (selectedOption !== "" && selectedOption !== "createOrg") {
      const selectedOrg = organisations.find(
        (org) => org.name === selectedOption
      );
      if (selectedOrg) {
        dispatch(
          fetchAssistants({ orgaisationId: selectedOrg.organisation_id })
        );
      }
    }
  }, [selectedOption, organisations, dispatch]);

  const handleCreateOrg = () => {
    setIsOrganisationModalVisible(true);
  };

  const handleModeChange = (checked) => {
    setIsDarkMode(checked);
  };

  const handleSelectChange = (value) => {
  
    if (value === "createOrg") {
      handleCreateOrg();
    } else {
      const selectedOrg = organisations.find((org) => org.name === value);
      if (selectedOrg) {
        localStorage.setItem(
          "selectedOrgIdFromSplash",
          selectedOrg.organisation_id
        );
        localStorage.setItem("selectedOrgNameFromSplash", value);
        localStorage.setItem(
          "selectedOrgUserRolesFromSplash",
          selectedOrg && selectedOrg?.user_roles
            ? selectedOrg?.user_roles[0].description
            : ""
        );
        setSelectedOption(value);
        navigate("/dashboard");
      }
    }
  };

  const handleWidgetControlClick = () => {

    console.log("Widget Control clicked");
  };
  

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
      <Menu.Item key="2" icon={<SlidersOutlined />} onClick={handleWidgetControlClick}>
        Widget Control
      </Menu.Item>
      <Menu.SubMenu key="3" icon={<SettingOutlined />} title="Setting">
        <Menu.Item key="darkMode">
          <span>Dark Mode</span>
          <Switch
            style={{ marginLeft: 10 }}
            checked={isDarkMode}
            onChange={handleModeChange}
          />
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
  

  return (
    <div className={`top-nav-bar ${isDarkMode ? "dark-mode" : "light-mode"}`}>
      <div className="nav-content">
        <header className="header">
          <div className="menu-items">
            <ul>
              <li>
                {isUser ? (
                  ""
                ) : (
                  <>
                    {assistants && (
                      <Link to="/createassistant" className="createAssistant">
                        <i className="fa fa-plus-circle" aria-hidden="true"></i>
                        &nbsp; Create Assistant
                      </Link>
                    )}
                  </>
                )}

                <Select
                  defaultValue={
                    organisations.length === 0
                      ? "Create Organisation"
                      : "Select Organisation"
                  }
                  placeholder={
                    organisations.length === 0
                      ? "Create Organisation"
                      : selectedOption
                  }
                  value={selectedOption}
                  style={{ width: 200 }}
                  className="custom-select"
                  onChange={handleSelectChange}
                  suffixIcon={
                    selectedOption !== "Create Organisation" &&
                    selectedOption !== "createOrg" && (
                      <CheckOutlined style={{ color: "lightgray" }} />
                    )
                  }
                >
                  {organisations.length > 0 ? (
                    organisations.map((org) => (
                      <Option key={org.organisation_id} value={org.name}>
                        {org.name}
                      </Option>
                    ))
                  ) : (
                    <Option value="noOrganisations" disabled>
                      No Organisations
                    </Option>
                  )}

                  <Option disabled key="separator" value="separator">
                    ───────────
                  </Option>

                  <Option key="createOrg" value="createOrg">
                    <b>Create Organisation</b>
                  </Option>
                </Select>
              </li>
              <li>
                <Dropdown overlay={menu} placement="bottomRight" arrow>
                  <Space>
                    <Avatar
                      style={{
                        backgroundColor: "lightgrey",
                        verticalAlign: "middle",
                      }}
                      size={35}
                      gap={0}
                      src={user?.picture}
                      alt="Avatar"
                    />
                    <DownOutlined style={{ fontSize: "small" }} />
                    <span>
                      {combineNames(user?.given_name, user?.family_name)}
                    </span>
                  </Space>
                </Dropdown>
              </li>
            </ul>
          </div>
        </header>
        <CreateOrganization
          isModalVisible={isOrganisationModalVisible}
          setIsModalVisible={setIsOrganisationModalVisible}
        />
      </div>
    </div>
  );
};

export default Navbar;
