import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosJWTInstance } from "../Utilities/axiosInstance";
import MINSKY_URL from "../config";

const initialState = {

    organisationMembers: [],
    loading: false


 
};

export const invitingMember = createAsyncThunk(
  "memberInvite/invitingMember",
  async ({ requestBody }, { rejectWithValue }) => {
    try {
      const response = await axiosJWTInstance.post(`${MINSKY_URL.inviteMember}`, requestBody);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        return rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);


export const fetchOrgMembers = createAsyncThunk(
    'organisationMembers/fetchOrgMembers',
    async ({ organizationId }, { rejectWithValue }) => {
      try {
        const response = await axiosJWTInstance.get(`${MINSKY_URL.getOrgMembers}${organizationId}/`);
        return response.data;
      } catch (error) {
        if (error.response && (error.response.status === 400 || error.response.status === 401)) {
          return rejectWithValue(error.response.data);
        } else {
          throw error;
        }
      }
    }
  );
  





export const manageTeamSlice = createSlice({
  name: "manageTeam",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(invitingMember.pending, (state, action) => {
        state.status = "pending";
        
      })
      .addCase(invitingMember.fulfilled, (state, action) => {
       
      })

      .addCase(invitingMember.rejected, (state, action) => {
        state.status = "failed";
       
      })

    //-----------------------------------------------------------------


    .addCase(fetchOrgMembers.pending, (state, action) => {
        state.status = "pending";
        state.loading = true;
        
      })
      .addCase(fetchOrgMembers.fulfilled, (state, action) => {
        state.organisationMembers = action.payload;
        state.loading = false;
       
      })

      .addCase(fetchOrgMembers.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
       
      });

  },
});

export const manageTeamReducer = manageTeamSlice.reducer;
