import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Card } from 'antd';

const PieChart = () => {
   
    ChartJS.register(ArcElement, Tooltip, Legend);

    const data = {
        labels: ['Legal Assistant', 'Work Assistant', 'Test Assistant', 'Fit Assistant'],
        datasets: [{
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.7)',
                'rgba(54, 162, 235, 0.7)', 
                'rgba(255, 206, 86, 0.7)', 
                'rgba(75, 192, 192, 0.7)', 
                'rgba(153, 102, 255, 0.7)', 
            ],
            borderColor: 'rgba(255, 255, 255, 0)', 
            borderWidth: 2,
            hoverBorderColor: 'rgba(255, 255, 255, 0.8)',
            hoverBorderWidth: 3,
            hoverOffset: 10,
        }],
    };

    const options = {
        responsive: true, 
        maintainAspectRatio: false, 
        plugins: {
            legend: {
                position: 'bottom', 
                labels: {
                    boxWidth: 15,
                    font: {
                        size: 14,
                        weight: 'bold'
                    }
                }
            },
            tooltip: {  // Adjusted to plugins.tooltip
                callbacks: {
                    label: function(context) {
                        let label = context.label || '';
                        if (label) {
                            label += ': ';
                        }
                        label += (context.parsed / context.dataset.data.reduce((a, b) => a + b) * 100).toFixed(2) + '%';
                        return label;
                    }
                }
            }
        },
    };

    return (
        <Card title="Assistant Usage" style={{ width: 500, margin: '7px', height: 350 }}>
            <Pie data={data} options={options} style={{ width:"505px", height:"224px"}} />
        </Card>
    );
};

export default PieChart;
