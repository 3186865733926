import React, { useState } from 'react';
import { Input, Button } from 'antd';
import './SearchInput.css';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';



const SearchInput = ({ onSearch, inputWidth, inputHeight }) => {
  const [query, setQuery] = useState('');

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(query);
  };

  const handleClear = () => {
    setQuery('');
  };


  return (
    <div className="search-bar-container">
        <div className="minsky-text">Minsky</div>

      <form onSubmit={handleSubmit}>
        <Input
          type="text"
          placeholder="Search..."
          value={query}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          className="search-input"
          prefix={
            query ? (
              <Button icon={<CloseOutlined />} onClick={handleClear} className="close-button"  />
            ) : (
              <SearchOutlined />
            )
          }
          style={{ width: inputWidth, height: inputHeight }}

        />
      </form>
    </div>
  );
};

export default SearchInput;
