import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./slices/authSlice";
import { assistantReducer } from "./slices/assistantSlice";
import { organisationtReducer } from "./slices/organisationSlice";
import { uploadDataReducer } from "./slices/uploadDataSlice";
import { manageTeamReducer } from "./slices/manageTeamSlice";



const reducer = {
    auth:authReducer,
    assistant:assistantReducer,
    organisation:organisationtReducer,
    uploadData:uploadDataReducer,
    manageTeam:manageTeamReducer

};

const store = configureStore({
  reducer: reducer, 
  devTools: true
  
});

export default store;
