import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, Select , message} from "antd"; // Import Select component from Ant Design
import { fetchOrganisations } from "../../../../slices/organisationSlice";
import { fetchAssistants } from "../../../../slices/assistantSlice";
import { useSelector, useDispatch } from "react-redux";
import { invitingMember , fetchOrgMembers} from "../../../../slices/manageTeamSlice";
import { fetchRoleTypes } from "../../../../slices/organisationSlice";

import * as Yup from "yup";

const { Option } = Select;

const initialValues = {
  roles: "",
  inviteeName: "",
  inviteeEmail: "",
  assistant: [],
};

const validationSchema = Yup.object().shape({
  roles: Yup.string().required("Role name is required"),
  inviteeName: Yup.string().required("Invitee name is required"),
  inviteeEmail: Yup.string()
    .email("Invalid email address")
    .required("Invitee email is required"),
  assistant: Yup.array()
    .required("Assistant name is required")
    .min(1, "At least one assistant must be selected"),
});

const InviteMembersModal = ({ setIsModalVisible }) => {
  const dispatch = useDispatch();
  const storedOrgId = localStorage.getItem("selectedOrgIdFromSplash");
  // const organisations = useSelector(
  //   (state) => state.organisation.organisationsFetch.organisations
  // );
  const assistants = useSelector((state) => state.assistant.assistants);

  const storedOrganisationId = localStorage.getItem("selectedOrgIdFromSplash");

  const roleTypes = useSelector(
    (state) => state.organisation.roleTypes
  );


  useEffect(() => {
    if (storedOrganisationId) {
      dispatch(fetchAssistants({ orgaisationId: storedOrganisationId }));
    }
  }, [dispatch, storedOrganisationId]);

  useEffect(() => {
   
      dispatch(fetchRoleTypes({}));
    
  }, [dispatch, storedOrganisationId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchOrganisations({}));
      } catch (error) {
        console.error("Error fetching organisations:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleSubmit = (values, {resetForm}) => {
    // console.log("Form submitted:", JSON.stringify(values));

    const requestBody = {
      roles:values.roles,
      organisation: storedOrganisationId,
      invitee_name: values.inviteeName,
      invitee_email: values.inviteeEmail,
      assistant: values.assistant
    }



    dispatch(invitingMember({ requestBody }))
      .then((response) => {
        console.log(response);
        if (response.payload.status === true) {
          // console.log(response);
          message.success(response.payload?.message)
          dispatch(fetchOrgMembers({organizationId:storedOrgId}));
          setIsModalVisible(false);
          
        } else {
          // console.log(response.error);
          message.error(response?.payload?.message); 
          setIsModalVisible(false);
          resetForm();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
 
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <div className="row mb-3">
            <label htmlFor="inviteeEmail" className="col-lg-3 col-form-label">
              Invitee Email
            </label>
            <div className="col-lg-7">
              <Field
                name="inviteeEmail"
                id="inviteeEmail"
                className="form-control"
              />
              <ErrorMessage
                name="inviteeEmail"
                component="div"
                className="error-message"
              />
            </div>
            </div>

          <div className="row mb-3">
            <label htmlFor="inviteeName" className="col-lg-3 col-form-label">
              Invitee Name
            </label>
            <div className="col-lg-7">
              <Field
                name="inviteeName"
                id="inviteeName"
                className="form-control"
              />
              <ErrorMessage
                name="inviteeName"
                component="div"
                className="error-message"
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="organization" className="col-lg-3 col-form-label">
              Roles
            </label>
            <div className="col-lg-7">
              <Field
                name="roles"
                as="select"
                className="form-select"
                disabled={roleTypes.length === 0}
              >
                {roleTypes.length > 0 && (
                  <option value="" disabled style={{ color: "grey" }}>
                    Select Role
                  </option>
                )}
                {roleTypes.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </Field>
              {roleTypes.length === 0 && (
                <div className="error-message">
                  Please select a role
                </div>
              )}
              <ErrorMessage
                name="roles"
                component="div"
                className="error-message"
              />
            </div>
          
          </div>

          <div className="row mb-3">
            <label htmlFor="assistant" className="col-lg-3 col-form-label">
              Select Assistant
            </label>
            <div className="col-lg-7">
              <div style={{ position: "relative" }}>
                <Select
                  name="assistant"
                  mode="multiple"
                  className="form-select"
                  onChange={(selected) => formik.setFieldValue("assistant", selected)}
                  value={formik.values.assistant}
                  style={{ padding: "0px" }}
                  disabled={assistants.length === 0}
                >
                  {assistants.length > 0 && (
                    <Option value="" disabled style={{ color: "grey" }}>
                      Select Assistants
                    </Option>
                  )}
                  {assistants.map((assistant) => (
                    <Option
                      key={assistant.assistant_id}
                      value={assistant.assistant_id}
                    >
                      {assistant.assistant_name}
                    </Option>
                  ))}
                </Select>
                {assistants.length === 0 && (
                  <div className="error-message">
                    Please create an Assistant first!
                  </div>
                )}
                {formik.values.assistant.length === 0 && (
                  <div className="error-message">
                    At least one assistant must be selected
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-7 offset-lg-3">
              <Button type="primary" htmlType="submit">
               Send Invite
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default InviteMembersModal;
