import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Home from "./components/dashboard/AllPages/Home/Home";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import NeuroAssist from "./components/dashboard/AllPages/Assistants/NeuroAssist";
import CreateAssistant from "./components/dashboard/AllPages/Assistants/CreateAsssitant/CreateAssistant";
import UploadData from "./components/dashboard/AllPages/Upload Data/UploadData";
import Dataset from "./components/dashboard/AllPages/Upload Data/FolderDataset/Dataset";
import UploadFile from "./components/dashboard/AllPages/Upload Data/UploadFile/UploadFile";
import InvitedLogin from "./components/dashboard/AllPages/MemberInvitation/InvitedLogin";
import ManageTeam from "./components/dashboard/AllPages/ManageTeam/ManageTeam";
import UserRoute from "./components/ProtectedRoutes/UserRoute";
import SelectOrgSplash from "./components/OrganisationSplash/SelectOrgSplash";
import Search from "../src/components/dashboard/AllPages/SearchBar/Search"

const AllRoutes = () => {
  const { isAuthenticated } = useAuth0();
  const [initialPage, setInitialPage] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const lastVisitPage = localStorage.getItem("lastVisitedPage");
  const isUser =
    localStorage.getItem("selectedOrgUserRolesFromSplash") === "user";

  useEffect(() => {
    if (isAuthenticated && !initialPage) {
      setInitialPage("/dashboard");
    }
  }, [isAuthenticated, initialPage]);

  useEffect(() => {
    if (isAuthenticated && initialPage) {
      localStorage.setItem("lastVisitedPage", location.pathname);
    }
  }, [isAuthenticated, initialPage, location.pathname]);

  useEffect(() => {
    if (isAuthenticated) {
      const lastVisitedPage = localStorage.getItem("lastVisitedPage");
      if (lastVisitedPage) {
        navigate(lastVisitedPage, { replace: true });
      }
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const isAuthenticatedAndInvited =
      !isAuthenticated &&
      localStorage.getItem("invitation") &&
      localStorage.getItem("organization") &&
      localStorage.getItem("organization_name");

    if (
      isAuthenticatedAndInvited &&
      location.pathname === "/organisation/login"
    ) {
      const { invitation, organization, organization_name } = localStorage;
      navigate(
        `/organisation/login?invitation=${invitation}&organization=${organization}&organization_name=${organization_name}`
      );
    }
  }, [isAuthenticated, location.pathname, navigate]);

  // console.log("initialPage:", initialPage);
  // console.log("lastVisitedPage:", lastVisitPage);

  return (
    <Routes>
      <Route
        path="/"
        element={
          isAuthenticated && (initialPage || lastVisitPage) ? (
            <SelectOrgSplash />
          ) : (
            <Home />
          )
        }
      />
     
     {isUser ? (
    <Route element={<UserRoute />}>
      <Route path="/dashboard" element={<Home />} />
      <Route path="/assistant/:id" element={<NeuroAssist />} />
    </Route>
  ) : (
    <>
      <Route path="/dashboard" element={<Home />} />
      <Route path="/search" element={<Search />} />
      <Route path="/assistant/:id" element={<NeuroAssist />} />
      <Route path="/createassistant" element={<CreateAssistant />} />
      <Route path="/upload" element={<UploadData />} />
      <Route path="/upload/dataset/:id/:title" element={<Dataset />} />
      <Route
        path="/upload/dataset/:id/:title/uploadfile"
        element={<UploadFile />}
      />
      <Route path="/manageteam" element={<ManageTeam />} />

      {!isAuthenticated && (
        <Route
          path="/organisation/login"
          element={<InvitedLogin setInitialPage={setInitialPage} />}
        />
      )}
    </>
  )}


      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default AllRoutes;
