import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosJWTInstance } from "../Utilities/axiosInstance";
import MINSKY_URL from "../config";
import { reloadPageWithoutUserNotice } from "../Utilities/axiosInstance"

const initialState = {
  organisationsFetch: {
    organisations: [],
  },
  roleTypes: [],
  isOrganisationCreatingLoader:false
};

export const createOrganisation = createAsyncThunk(
  "organisation/createOrganisation",
  async ({ requestBody }, { rejectWithValue }) => {
    try {
      const response = await axiosJWTInstance.post(
        `${MINSKY_URL.createOrganisation}`,
        requestBody
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        return rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

//------------------- Role Types --------------------------

export const fetchRoleTypes = createAsyncThunk(
  "getRoleTypes/fetchRoleTypes",
  async () => {
    try {
      const response = await axiosJWTInstance.get(`${MINSKY_URL.roleTypes}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchOrganisations = createAsyncThunk(
  "organisation/fetchOrganisations",
  async () => {
    try {
      const response = await axiosJWTInstance.get(
        `${MINSKY_URL.getOrganisations}`
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching organisations:", error);

      if (error.response && error.response.status === 403) {
        console.error(
          "Access forbidden. You may not have permission to access this resource."
        );
      
        reloadPageWithoutUserNotice();
        
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }

      throw error;
    }
  }
);

export const organisationSlice = createSlice({
  name: "organisation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createOrganisation.pending, (state, action) => {
        state.status = "pending";
        state.isOrganisationCreatingLoader = true;
      })
      .addCase(createOrganisation.fulfilled, (state, action) => {
        state.isOrganisationCreatingLoader = false;

      })

      .addCase(createOrganisation.rejected, (state, action) => {
        state.status = "failed";
        state.isOrganisationCreatingLoader = false;
      })
      //----------------------------------------------------------

      .addCase(fetchOrganisations.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchOrganisations.fulfilled, (state, action) => {
        const { invited_organisation, created_organisation } = action.payload;

        const invitedOrganisations = invited_organisation.map((org) => ({
          organisation_id: org.organisation_id,
          name: org.name,
          user_roles: org.user_roles.map((role) => ({
            id: role.id,
            // name: role.name,
            description: role.description,
          })),
        }));

        const allOrganisations = [
          ...invitedOrganisations,
          ...created_organisation.map((org) => ({
            organisation_id: org.organisation_id,
            name: org.name,
          })),
        ];

        state.organisationsFetch.organisations = allOrganisations;
        state.status = "fulfilled";
      })
      .addCase(fetchOrganisations.rejected, (state, action) => {
        state.status = "failed";
      })

      //-----------------------------------------------------------------
      .addCase(fetchRoleTypes.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchRoleTypes.fulfilled, (state, action) => {
        const roleTypes = action.payload.map((role) => ({
          id: role.id,
          name: role.name,
        }));

        state.roleTypes = roleTypes;
      })

      .addCase(fetchRoleTypes.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const organisationtReducer = organisationSlice.reducer;
