import React, { useState } from "react";
import { Breadcrumb, Card, message, Upload, Button } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InboxOutlined, LinkOutlined, FileOutlined} from "@ant-design/icons";
import SideNavBar from "../../../SideNavBar/SideNavBar";
import Navbar from "../../../Navbar/Navbar";
import { uploadFileVectorDB } from "../../../../../slices/uploadDataSlice";
import { useDispatch } from "react-redux";
import UploadUrl from "../Upload Url/UploadUrl";
import { uploadUrlVectorDB } from "../../../../../slices/uploadDataSlice";
import "../UploadFile/UploadFile.css"


const UploadFile = () => {
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [isUploadFile, setIsUploadFile] = useState("fileUpload");
  const navigate = useNavigate();
  const { id: folderId, title: folderTitle } = useParams();

  const handleUploadChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const storedOrganisationId = localStorage.getItem("selectedOrgIdFromSplash")

  const handleRemove = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
  };


  const handleUploadUrlSubmit = async (values, { setSubmitting }) => {

    const loadingMessage = message.loading("Uploading URL...", 0);

  const { urls } = values;

  if (!urls) {
    message.error("Please enter at least one URL.");
    return;
  }

  const urlArray = urls.split("\n").filter((url) => url.trim() !== "");

  const requestBody = {
    collection: folderId,
    organisation: storedOrganisationId,
    url: urlArray,
  };

  dispatch(uploadUrlVectorDB({ requestBody }))
    .then((response) => {
      message.success(response.payload[0]?.message);
      loadingMessage();
      navigate(`/upload/dataset/${folderId}/${folderTitle}`);
    })
    .catch((error) => {
      console.error("Error:", error);
    })
    .finally(() => {
      setSubmitting(false);
      loadingMessage();
    });

  };

   

  const handleUpload = async () => {
    if (isUploadFile === "fileUpload") {
      if (fileList.length === 0) {
        message.error("Please select a file to upload!");
        return;
      }

      const loadingMessage = message.loading("Uploading files...", 0);

      const formData = new FormData();
      for (const file of fileList) {
        formData.append("file", file.originFileObj);
      }
      formData.append(
        "organisation",
        localStorage.getItem("selectedOrgIdFromSplash")
      );
      formData.append("collection", folderId);

      try {
        const response = await dispatch(uploadFileVectorDB({ formData }));
        // console.log(response);
        loadingMessage();
        if (Array.isArray(response.payload)) {
          response.payload.forEach((item) => {
            if (item.status === true) {
              message.success(item.message);
            } else {
              message.error(item.message || "Upload failed");
            }
            navigate(`/upload/dataset/${folderId}/${folderTitle}`);
            setFileList([]);
          });
        } else {
          message.error("Invalid response received from the server.");
        }
      } catch (error) {
        console.error("Error uploading files:", error);
        message.error(
          "An error occurred while uploading the files. Please try again later."
        );
        loadingMessage();
      }
    } else {
      
    }
  };

  const handleUploadFileOption = () => {
    setIsUploadFile("fileUpload");
  };

  const handleUploadUrlOption = () => {
    setIsUploadFile("uploadUrl");
  };

  const props = {
    name: "file",
    multiple: true,
    fileList,
    beforeUpload: (file) => {
      const isPDF = file.type === "application/pdf";
      const isText = file.type === "text/plain";
      if (!isPDF && !isText) {
        message.error("You can only upload PDF or plain text files!");
        return false;
      }
      setFileList([...fileList, file]); // Add the file to the fileList
      return false; // Prevent automatic upload
    },
    onChange: handleUploadChange,
    onRemove: handleRemove,
  };

  return (
    <div>
      <SideNavBar />
      <Navbar />
      <div className="content">
        <Breadcrumb style={{ margin: 0 }}>
          <Breadcrumb.Item>
            <Link to="/upload">Knowledge Base</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/upload/dataset/${folderId}/${folderTitle}`}>
              {folderTitle}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Upload</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ marginTop: "50px", display: "flex", justifyContent: "flex-start" }}>
          <Card
            className={`upload-card ${isUploadFile === "fileUpload" ? "selected-cardd" : ""}`}
            onClick={handleUploadFileOption}
          >
            <Card.Meta
              avatar={<FileOutlined/>}
              title="Upload File"
            />
          </Card>
          <Card
            className={`upload-card ${isUploadFile === "uploadUrl" ? "selected-cardd" : ""}`}
            onClick={handleUploadUrlOption}
          >
            <Card.Meta
              avatar={<LinkOutlined />}
              title="Upload URL"
            />
          </Card>
        </div>

        {isUploadFile === "fileUpload" ? (
          <>
            <Upload.Dragger
              {...props}
              width={200}
              height={200}
              accept=".pdf,.txt"
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Only PDF and plain text files are supported.
              </p>
            </Upload.Dragger>
            <div style={{ marginTop: "16px", textAlign: "center" }}>
              <Button
                type="primary"
                onClick={handleUpload}
                style={{ width: "200px" }}
              >
                Next
              </Button>
            </div>
          </>
        ) : (
          <UploadUrl handleSubmit={handleUploadUrlSubmit} />
        )}
      </div>
    </div>
  );
};

export default UploadFile;
