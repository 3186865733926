import React, { useEffect, useState } from 'react';
import axios from 'axios'; 
import { useSelector, useDispatch } from 'react-redux';
import { fetchCollectionsInfo } from '../../../../slices/uploadDataSlice';
import MINSKY_URL from '../../../../config';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const dispatch = useDispatch();
  const storedOrganisationId = localStorage.getItem("selectedOrgIdFromSplash");

  

  useEffect(() => {
    dispatch(fetchCollectionsInfo({ orgaisationId: storedOrganisationId }));
  }, [dispatch, storedOrganisationId]);

  const fetchedCollectionsInfo = useSelector((state) => state.uploadData.totalCollections);
  const [selectedCollectionId, setSelectedCollectionId] = useState(null); 

  const handleHello = () => {
    if (fetchedCollectionsInfo && fetchedCollectionsInfo.length > 0) {
      const collectionOptions = fetchedCollectionsInfo.map(collection => ({
        value: collection.id,
        label: collection.db_collection,
      }));

      const botMessage = createChatBotMessage('Hello. Nice to meet you. Please select a collection to move forward.', {
        withAvatar: true,
        delay: 500,
        widget: 'collectionOptions',
        options: collectionOptions,
      });

      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
      }));
    } else {
      const botMessage = createChatBotMessage("There are no collections available. Please make a collection first");

      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
      }));
    }
  };

  const handleCollectionSelection = (collectionName) => {
    console.log("Collection selected:", collectionName);
  
    const selectedCollection = fetchedCollectionsInfo.find(collection => collection.db_collection === collectionName);
  
    if (selectedCollection) {
        const botMessages = [
            createChatBotMessage(
                `You've selected the collection: ${selectedCollection.db_collection}.`
            ),
            createChatBotMessage(
                "Please write your query."
            )
        ];
  
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, ...botMessages],
        }));
  
        setSelectedCollectionId(selectedCollection.id); 
    } else {
        const botMessage = createChatBotMessage("Collection not found.");
  
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    }
};


  const handleDefault = async (query) => {
    try {
      const response = await axios.post(`${MINSKY_URL.getUserQueryResponseChatbot}`, { query,  collection: selectedCollectionId}); 
      const data = response.data;
      
      const respQuery = data?.answer;

      if (data && respQuery) {
        const botMessage = createChatBotMessage(respQuery);

        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, botMessage],
        }));
      } else {
        const botMessage = createChatBotMessage("We are working on it. Please try after some time.");

        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, botMessage],
        }));
      }
    } catch (error) {
      console.error('Error fetching data:', error);

      const botMessage = createChatBotMessage("We are working on it. Please try after some time.");

      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
      }));
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleHello,
            handleDefault,
            handleCollectionSelection,
            options: fetchedCollectionsInfo
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
