import React, { useEffect, useState } from "react";
import SideNavBar from "../../../SideNavBar/SideNavBar";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Navbar from "../../../Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  createAssistant,
  fetchAssistants,
  updateAssistantPrompt
} from "../../../../../slices/assistantSlice";
import { fetchCollectionsInfo } from "../../../../../slices/uploadDataSlice";
import { message } from "antd";
import "./CreateAssistant.css";
import { resetPromptTemplate } from "../../../../../slices/assistantSlice";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  assistant_name: Yup.string().required("Assistant Name is required"),
  assistant_description: Yup.string().required(
    "Assistant Description is required"
  ),
  db_collection: Yup.string().required("DB Collection is required"),
});

const initialValues = {
  assistant_name: "",
  assistant_description: "",
  db_collection: "",
};

const CreateAssistant = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const promptTemplate = useSelector(
    (state) => state.assistant.createAssist.prompt_template
  );

  const assistantIdOfPromptTemplate = useSelector(
    (state) => state.assistant.createAssist.assistant_id
  );

  // console.log(assistantIdOfPromptTemplate, "assistantIdOfPromptTemplate");

  // console.log("template:::", promptTemplate);
  const storedOrganisationId = localStorage.getItem("selectedOrgIdFromSplash");

  useEffect(() => {
    dispatch(fetchCollectionsInfo({ orgaisationId: storedOrganisationId }));
  }, [dispatch, storedOrganisationId]);

  useEffect(() => {
    return () => {
      dispatch(resetPromptTemplate());
    };
  }, [dispatch]);

  const { isLoading } = useSelector(
    (state) => state.assistant.createAssist.isLoading
  );

  const fetchedCollectionsInfo = useSelector(
    (state) => state.uploadData.totalCollections
  );

  const [isEditing, setIsEditing] = useState(false);
  const [editedPromptTemplate, setEditedPromptTemplate] = useState(
    promptTemplate
  );

  useEffect(() => {
    setEditedPromptTemplate(promptTemplate);
  }, [promptTemplate]);

  const handleSubmit = (values, { resetForm }) => {
    const { assistant_name, assistant_description, db_collection } = values;

    const requestBody = {
      organisation: storedOrganisationId,
      assistant_name,
      assistant_description,
      db_collection,
    };

    dispatch(createAssistant(requestBody))
      .then((res) => {
        dispatch(fetchAssistants({ orgaisationId: storedOrganisationId }));
        message.success(res.payload?.message);
        resetForm();
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const handleEdit = () => {
    if (isEditing) {
      const requestBody = {
        assistant: assistantIdOfPromptTemplate,
        prompt_template: editedPromptTemplate,
      };

      dispatch(updateAssistantPrompt(requestBody))
        .then((res) => {
          message.success(res.payload?.message);
          navigate('/dashboard')
        })
        .catch((err) => {
          console.log("Error: ", err);
        });
    }

    setIsEditing(!isEditing);
  };

  const handlePromptChange = (e) => {
    const startTag = "<s> [INST] ";
    const endTag =
      " [/INST] </s> [INST] User Query: {query} Context: {context} Answer: [/INST]";

    const newValue =
      startTag +
      e.target.value.substring(
        startTag.length,
        e.target.value.length - endTag.length
      ) +
      endTag;

    setEditedPromptTemplate(newValue);

    
  };

  return (
    <div className="container">
      <SideNavBar />
      <Navbar />
      <div className="content">
        <h3>Create an Assistant</h3>
        <hr className="content-space" />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form className="row g-3">
            <div className="col-md-4">
              <label htmlFor="assistant_name" className="form-label">
                Assistant Name
              </label>
              <Field
                type="text"
                name="assistant_name"
                className="form-control"
              />
              <ErrorMessage
                name="assistant_name"
                component="div"
                className="error"
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="assistant_description" className="form-label">
                Assistant Description
              </label>
              <Field
                type="text"
                name="assistant_description"
                className="form-control"
              />
              <ErrorMessage
                name="assistant_description"
                component="div"
                className="error"
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="db_collection" className="form-label">
                DB Collection
              </label>
              <Field
                as="select"
                name="db_collection"
                className="form-control"
                disabled={fetchedCollectionsInfo.length === 0}
              >
                {fetchedCollectionsInfo.length > 0 && (
                  <option value="" disabled style={{ color: "grey" }}>
                    Select DB Collection
                  </option>
                )}
                {fetchedCollectionsInfo.map((collection) => (
                  <option key={collection.id} value={collection.id}>
                    {collection.db_collection}
                  </option>
                ))}
              </Field>
              {fetchedCollectionsInfo.length === 0 && (
                <div className="error">
                  Please make a Knowledge base to create an Assistant!
                </div>
              )}
              {fetchedCollectionsInfo.length > 0 && (
                <ErrorMessage
                  name="db_collection"
                  component="div"
                  className="error"
                />
              )}
            </div>

            <div className="col-12 createChatSubmit">
              <button
                type="submit"
                className="btn btn-primary btn-wide btn-tall"
                disabled={isLoading}
              >
                Create
              </button>
            </div>
          </Form>
        </Formik>
        <hr className="assistant-space" />
        <div className="col-md-12">
          {promptTemplate?.length > 0 && (
            <div className="textarea-container">
              <label htmlFor="prompt_template" className="form-label">
              Assistant Prompt
              </label>
              <textarea
                id="prompt_template"
                name="prompt_template"
                className="form-control"
                value={editedPromptTemplate}
                onChange={handlePromptChange}
                disabled={!isEditing}
                style={{ height: "100%" }}
              />
              <button
                type="button"
                className="btn btn-primary mt-2"
                onClick={handleEdit}
              >
                {isEditing ? "Save" : "Edit"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateAssistant;
