import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { domain, clientId, audience, scopes, redirectUri } from "../../../../Utilities/authConfig";

const generateRandomState = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
};

const InvitedLogin = () => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const invitation = searchParams.get("invitation");
  const organization = searchParams.get("organization");
  const organizationName = searchParams.get("organization_name");

  const [redirecting, setRedirecting] = useState(false);
  

  useEffect(() => {
    const redirectToAuthorization = async () => {
      try {
        if (!isAuthenticated && organization && organizationName && !redirecting) {
          localStorage.setItem("invitation", invitation);
          localStorage.setItem("organization", organization);
          localStorage.setItem("organization_name", organizationName);

          const authorizationUrl = `${domain}/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&audience=${audience}&scope=${scopes}&state=${generateRandomState()}&invitation=${invitation}&organization=${organization}&organization_name=${organizationName}`;

          setRedirecting(true);

         
            window.location.href = authorizationUrl;
            setRedirecting(false); 
       
        }
      } catch (error) {
        console.error("Authorization error:", error);
       
        navigate('/error');
      }
    };

    redirectToAuthorization();
  }, [invitation, organization, organizationName, isAuthenticated, redirecting, navigate]);

  return null;
};

export default InvitedLogin;



//---------------------------------------------------------------------------------------------


// import { useEffect, useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { useAuth0 } from "@auth0/auth0-react";
// import axios from "axios";
// import { domain, clientId, audience, scopes, redirectUri } from "../../../../Utilities/authConfig";

// const generateRandomState = () => {
//   return (
//     Math.random().toString(36).substring(2, 15) +
//     Math.random().toString(36).substring(2, 15)
//   );
// };

// const InvitedLogin = () => {
//   const { isAuthenticated } = useAuth0();
//   const navigate = useNavigate();
//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);

//   const invitation = searchParams.get("invitation");
//   const organization = searchParams.get("organization");
//   const organizationName = searchParams.get("organization_name");

//   const [redirecting, setRedirecting] = useState(false);

//   useEffect(() => {
//     const redirectToAuthorization = async () => {
//       try {
//         if (!isAuthenticated && organization && organizationName && !redirecting) {
//           localStorage.setItem("invitation", invitation);
//           localStorage.setItem("organization", organization);
//           localStorage.setItem("organization_name", organizationName);

//           const state = generateRandomState();
//           const authorizationUrl = `${domain}/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&audience=${audience}&scope=${scopes}&state=${state}&invitation=${invitation}&organization=${organization}&organization_name=${organizationName}`;

//           setRedirecting(true);

         
//           const response = await axios.get(authorizationUrl);

         
//           if (response.status === 200) {
         
//             console.log("Authorization successful");
//           } else {
        
//             console.error("Authorization error:", response.data);
          
//             navigate('/error');
//           }

//           setRedirecting(false); 
//         }
//       } catch (error) {
//         console.error("Authorization error:", error);
  
//         navigate('/error');
//       }
//     };

//     redirectToAuthorization();
//   }, [invitation, organization, organizationName, isAuthenticated, redirecting, navigate]);

//   return null;
// };

// export default InvitedLogin;
