import React from "react";
import { Modal, Button, message } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  createOrganisation
} from "../../slices/organisationSlice"
import { getUserRoleAndItsOrg } from "../../slices/authSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

const CreateOrganization = ({ isModalVisible, setIsModalVisible }) => {
  const dispatch = useDispatch();
  const { user } = useAuth0();

  const { isOrganisationCreatingLoader } = useSelector(
    (state) => state.organisation
  );


  const initialValues = {
    organisationName: "",
    displayName: "",
    logoUrl: "",

  };

  const validationSchema = Yup.object().shape({
    organisationName: Yup.string()
      .matches(
        /^[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*$/,
        'Organisation Name must not contain spaces between words and only one "_" character is accepted'
      )
      .required("Organisation Name is required"),

    displayName: Yup.string().required("Display Name is required"),
    logoUrl: Yup.string()
      .matches(/^(https:\/\/)/, "URL must start with https://")
      .url("Invalid URL format")
      .required("Logo URL is required"),
    // primaryColor: Yup.string().required("Primary Color is required"),
    // backgroundColor: Yup.string().required("Background Color is required"),
    // connectionId: Yup.string().required("Connection ID is required"),
  });

  const handleCancel = (resetForm) => {
    setIsModalVisible(false);
    resetForm();
  };



  const organisationDetailsSubmit = (values, { resetForm }) => {
    const requestBody = {
      admin: user?.sub,
      organisation_name: values.organisationName,
      display_name: values.displayName,
      logo_url: values.logoUrl,
      primary: "#FF5733",
      page_background: "#FFFFFF",
      metadata: { user: user?.sub },
      connection_id: "con_zhyftCvgcvig9cyD",
    };

    dispatch(createOrganisation({ requestBody }))
      .then((response) => {
        console.log(response);
        if (response.payload.status === true) {
          // console.log(response);
          message.success(response.payload?.message)
          dispatch(getUserRoleAndItsOrg({}));
          resetForm();
          setIsModalVisible(false);
        } else {
          // console.log(response.error);
          message.error(response?.payload?.message); 
          setIsModalVisible(false);
          resetForm();
        }
      })
      .catch((error) => {
        console.error("Error sending audio data to API:", error);
      });
  };

  return (
    <Modal
      title="Create Organisation"
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={null}
      closeIcon={null}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={organisationDetailsSubmit}
      >
        {(formik) => (
          <Form>
            <div className="row justify-content-center">
              <div className="col-6 mb-3">
                <label htmlFor="organisationName" className="form-label">
                  Organisation Name
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="organisationName"
                  name="organisationName"
                  placeholder="Enter Organisation Name"
                />
                <ErrorMessage
                  name="organisationName"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="col-6 mb-3">
                <label htmlFor="displayName" className="form-label">
                  Display Name
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="displayName"
                  name="displayName"
                  placeholder="Enter Display Name"
                />
                <ErrorMessage
                  name="displayName"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="col-6 mb-3">
                <label htmlFor="logoUrl" className="form-label">
                  Logo URL
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="logoUrl"
                  name="logoUrl"
                  placeholder="Enter Logo URL"
                />
                <ErrorMessage
                  name="logoUrl"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="col-6 mb-3">
              </div>
            </div>
            <div className="col-lg-12 d-flex justify-content-end">
              <Button
                key="cancel"
                className="me-2"
                onClick={() => handleCancel(formik.resetForm)}
              >
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" className="mb-3" disabled={isOrganisationCreatingLoader}>
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateOrganization;
