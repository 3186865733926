import React, { useEffect } from 'react';
import { Outlet ,useNavigate} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react'; 

const UserRoute = () => {

  const { logout } = useAuth0(); 
  const navigate = useNavigate();


  const isUser = localStorage.getItem("selectedOrgUserRolesFromSplash")

 
//   const isSuperAdmin = roles?.some(role => role?.description === 'superadmin'); 

useEffect(() => {
  if (!isUser) {
    navigate('/dashboard')
  }
}, [isUser, logout, navigate]);

  return isUser ? <Outlet /> : navigate('dashboard');
};

export default UserRoute;
