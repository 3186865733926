import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import {
  assistantQuery,
  fetchAssistantChats,
} from "../../../../slices/assistantSlice";
import { SendOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Tooltip, Spin, Select, Card } from "antd";
import "./NeuroAssist.css";
import SideNavBar from "../../SideNavBar/SideNavBar";
import Navbar from "../../Navbar/Navbar";
import { useParams } from "react-router-dom";
import RefreshChat from "../../../../assets/Images/loading.png";
import { Collapse } from "antd";

const { Panel } = Collapse;

const { Option } = Select;

const MessageSchema = Yup.object().shape({
  message: Yup.string()
    .required("Message is required")
    .max(500, "Message must be at most 500 characters long"),
});

const NeuroAssist = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user, isAuthenticated } = useAuth0();
  const [requestPending, setRequestPending] = useState(false);
  const [showSources, setShowSources] = useState(false);

  const storedChatAfterAChatClicked = useSelector(
    (state) => state.assistant?.assistantChatHistory
  );
  const storedChatIdAfterAChatClicked = useSelector(
    (state) => state.assistant?.storedChatIdAfterChatClick
  );
  const storedChatIdAfterAssistantQuery = useSelector(
    (state) => state.assistant?.assistantQuery?.chat
  );

  const [mode, setMode] = useState("Chat Assistant");

  const dispatch = useDispatch();
  const { id } = useParams();
  const assistantId = id;

 
  const assistants = useSelector((state) => state.assistant.assistants);

  const selectedAssistant = assistants.find(
    (assistant) => assistant.assistant_id === assistantId
  );

  const selectedAssistantName = selectedAssistant
    ? selectedAssistant.assistant_name
    : "Assistant not found";

  const storedChatIdInLocalStorage = localStorage.getItem("ChatId");

  useEffect(() => {
    if (storedChatIdInLocalStorage) {
      if (
        storedChatAfterAChatClicked &&
        storedChatIdAfterAChatClicked === storedChatIdInLocalStorage
      ) {
        setMessages(
          storedChatAfterAChatClicked.map((chat, index) => ({
            id: index + 1,
            message: chat.chat,
            role: chat.role === "Assistant" ? "chatbot" : "user",
          }))
        );
      }
    } else {
      setMessages([
        {
          id: 1,
          message:
            "Hello, I am Minsky, your AI Assistant. How may I help you today?",
          role: "chatbot",
        },
      ]);
    }
  }, [
    storedChatIdInLocalStorage,
    storedChatAfterAChatClicked,
    storedChatIdAfterAChatClicked,
    dispatch,
  ]);
  

  useEffect(() => {
    setMessages([
      {
        id: 1,
        message:
          "Hello, I am Minsky, your AI Assistant. How may I help you today?",
        role: "chatbot",
      },
    ]);
  }, [id, dispatch]);

  const handleModeChange = (value) => {
    setMode(value);
  };

  const typeMessage = (messageText, setMessage) => {
    let index = 0;
    const typingInterval = setInterval(() => {
      setMessage(messageText.substring(0, index + 1));
      index++;
      if (index === messageText.length) {
        clearInterval(typingInterval);
      }
    }, 5);
  };

  const handleSubmit = async (values, { resetForm }) => {
    if (requestPending) {
      return;
    }

    setLoading(true);
    setRequestPending(true);

    const newMessage = {
      id: messages.length + 1,
      message: values.message,
      role: "user",
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    let chat = "";

    if (storedChatIdAfterAChatClicked === storedChatIdInLocalStorage) {
      chat = storedChatIdAfterAChatClicked;
    } else if (storedChatIdInLocalStorage === null) {
      chat = "";
    } else if (
      storedChatAfterAChatClicked === storedChatIdAfterAssistantQuery
    ) {
      chat = storedChatIdAfterAssistantQuery;
    } else if (storedChatIdInLocalStorage !== null) {
      chat = storedChatIdInLocalStorage;
    } else {
      chat = "";
    }

    const requestBody = {
      chat: chat,
      query: values.message,
      assistant: assistantId,
    };

    try {
      const response = await dispatch(assistantQuery(requestBody));

      const botMessage = {
        id: messages.length + 2,
        message: response.payload.answer,
        role: "chatbot",
        typing: true,
      };

      setMessages((prevMessages) => [...prevMessages, botMessage]);

      typeMessage(
        response.payload.answer,
        (typedMessage) => {
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === botMessage.id
                ? { ...msg, message: typedMessage, typing: false }
                : msg
            )
          );

          const typingDelay = typedMessage.length * 90;
          setTimeout(() => {
            setShowSources(true);
          }, typingDelay + 4000);
        },
        5
      );

      dispatch(fetchAssistantChats({ assistantId: id }));

      resetForm();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
      setRequestPending(false);
    }
  };

  const handleRefreshChat = () => {
    window.location.reload();
    localStorage.setItem("ChatId", "");
  };

  return (
    <div className="chat-app">
      <SideNavBar />
      <Navbar />
      <div className="chat_content">
        <div className="chat-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <h2 style={{ marginLeft: "25px", padding: "10px" }}>
              {selectedAssistantName} by Neurobridge !
            </h2>

            <div
              style={{ marginRight: "12px", marginTop: "28px" }}
              className="setChatMode"
            >
              <Select defaultValue={mode} onChange={handleModeChange}>
                <Option value="Chat Assistant">Chat Assistant</Option>
                <Option value="Doc Q & A">Doc Q & A</Option>
              </Select>
            </div>
          </div>
          <div className="conversation-container">
            {messages.map((message, index) => (
              <div key={message.id} className={`message ${message.role}`}>
                <div className="avatar-container">
                  <Avatar
                    className={`avatar ${
                      message.role === "user" ? "user-avatar" : "chatbot-avatar"
                    }`}
                    size={30}
                    icon={
                      message.role === "user" ? (
                        <UserOutlined />
                      ) : (
                        <img
                          src="/favicon.ico"
                          alt="Chatbot"
                          style={{ width: "100%", height: "100%" }}
                        />
                      )
                    }
                    src={
                      message.role === "user" && isAuthenticated
                        ? user.picture
                        : null
                    }
                  />
                </div>
                <div className="message-content">
                  {message.role === "chatbot" &&
                    index === messages.length - 1 &&
                    message.message !==
                      "Hello, I am Minsky, your Langchain Assistant. How may I help you today?" &&
                    showSources && (
                      <div className="collapse-panel">
                        <Collapse bordered={false} defaultActiveKey={[]}>
                          <Panel
                            header="Show Sources"
                            key={`${index + 1}`}
                            style={{ fontWeight: "bold" }}
                          >
                            <div className="card-container">
                              <Card
                                title="Card title 1"
                                size="small"
                                className="card card-enter"
                              >
                                <p className="card-title">Card content 1</p>
                              </Card>
                              <Card
                                title="Card title 2"
                                size="small"
                                className="card card-enter"
                              >
                                <p className="card-title">Card content 2</p>
                              </Card>
                              <Card
                                title="Card title 3"
                                size="small"
                                className="card card-enter"
                              >
                                <p className="card-title">Card content 3</p>
                              </Card>
                            </div>
                          </Panel>
                        </Collapse>
                      </div>
                    )}
                  <div
                    className={`text ${
                      message.role === "chatbot" ? "chatbot-message" : ""
                    }`}
                  >
                    {message.message}
                  </div>
                  <Spin
                    indicator={
                      <div className="loader">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    }
                    spinning={
                      loading &&
                      message.role === "user" &&
                      index === messages.length - 1
                    }
                    style={{ textAlign: "center", display: "flex" }}
                  ></Spin>
                </div>
              </div>
            ))}
          </div>
          <div className="refreshChat">
            <Tooltip title="Refresh Chat">
              <img
                src={RefreshChat}
                alt=""
                onClick={handleRefreshChat}
                width={25}
                height={25}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </div>

          <Formik
            initialValues={{ message: "" }}
            validationSchema={MessageSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form className="message-form">
                <Field
                  type="text"
                  name="message"
                  style={{ borderRadius: "35px", background: "lightgrey" }}
                  placeholder="Enter your query here"
                  className={`message-input ${
                    errors.message && touched.message ? "error" : ""
                  }`}
                />
                <div className="message-input-container">
                  <button type="submit" className="sendChatText">
                    <Tooltip title="Send Message">
                      <SendOutlined
                        className="send-icon"
                        style={{ color: requestPending ? "grey" : "" }}
                      />
                    </Tooltip>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default NeuroAssist;
