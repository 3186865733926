import React, { useState, useEffect } from "react";
import SideNavBar from "../../SideNavBar/SideNavBar";
import Navbar from "../../Navbar/Navbar";
import { Button, Modal, Table, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { fetchOrgMembers } from "../../../../slices/manageTeamSlice";
import InviteMembersModal from "../MemberInvitation/InviteMembersModal";
import "./ManageTeam.css";

const ManageTeam = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();

  const organisationMembersData = useSelector(
    (state) => state.manageTeam.organisationMembers.data
  );
  const loading = useSelector((state) => state.manageTeam.loading);

//   console.log("organisationMembersData : ", organisationMembersData);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const storedOrgId = localStorage.getItem("selectedOrgIdFromSplash");

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchOrgMembers({ organizationId: storedOrgId })).then(
          (response) => {
            // message.error(response.payload.detail)
          }
        );
      } catch (error) {
        console.error("Error fetching organisations:", error);
      }
    };

    fetchData();
  }, [storedOrgId, dispatch]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    //     {
    //         title: 'Created at',
    //         dataIndex: 'createdAt',
    //         key: 'createdAt',
    //     },
    //     {
    //         title: 'Created By',
    //         dataIndex: 'createdBy',
    //         key: 'createdBy',
    //     },
    //     {
    //       title: 'Action',
    //       dataIndex: 'action',
    //       key: 'action',
    //   },
  ];

  return (
    <div>
      <SideNavBar />
      <Navbar />
      <div className="content">
        <h3 className="homeHeading">Manage Team</h3>
        <div className="button-container">
          <Button
            type="primary mt-5"
            icon={<PlusOutlined />}
            onClick={openModal}
          >
            Invite members
          </Button>
        </div>
        <hr className="content-space" />

        <Modal
          title="Invite Members"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <InviteMembersModal setIsModalVisible={setIsModalVisible} />
        </Modal>
        <div className="table-header">
          <h4 className="table-heading">Invitations</h4>
          <p className="table-subheading">
            Invite users to become members of your organization.
          </p>
        </div>

        {loading ? (
          <div className="spinner-container">
            <Spin size="medium" />
          </div>
        ) : (
          <Table dataSource={organisationMembersData} columns={columns} />
        )}
      </div>
    </div>
  );
};

export default ManageTeam;
