import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Card, Button, Spin } from "antd";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Minsky from "../../assets/Images/favicon.png";
import CreateOrgModal from "./CreateOrgModal";
import { LogoutOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";

import "./SelectOrgSplash.css";
import { getUserRoleAndItsOrg } from "../../slices/authSlice";


const SelectOrgSplash = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { logout } = useAuth0();

  const invitedOrganisations = useSelector(
    (state) => state.auth.UserRoleAndItsOrg.invited_organisation
  );

  const createdOrganisations = useSelector(
    (state) => state.auth.UserRoleAndItsOrg.created_organisation
  );

  useEffect(() => {
    dispatch(getUserRoleAndItsOrg());
  }, [dispatch]);

  

  const isLoading = useSelector(
    (state) => state.auth.loadingUserRoleAndItsOrg
  );


  // console.log(isLoading,"dsakldksa")
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [isOrganisationModalVisible, setIsOrganisationModalVisible] =
    useState(false);
  const [isNavigating, setIsNavigating] = useState(false);

  const handleSubmit = (selectedOrg) => {
    localStorage.setItem(
      "selectedOrgIdFromSplash",
      selectedOrg.organisation_id
    );
    localStorage.setItem("selectedOrgNameFromSplash", selectedOrg.name);
    localStorage.setItem(
      "selectedOrgUserRolesFromSplash",
      selectedOrg && selectedOrg?.user_roles
        ? selectedOrg?.user_roles[0].description
        : ""
    );

    setIsNavigating(true);
    setTimeout(() => {
      navigate("/dashboard");
    }, 800);
  };

  const handleCardClick = (org) => {
    handleSubmit(org);
    setSelectedOrg(org);
  };

  const toCamelCase = (name) => {
  

    const words = name.split(" ");
    const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return capitalizedWords.join("");
};


  const handleCreateOrg = () => {
    setIsOrganisationModalVisible(true);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    logout({ returnTo: window.location.origin });
  };



  return (
    <div className="splash-container">
      <Button
        className="logout-button"
        type="primary"
        onClick={handleLogout}
        icon={<LogoutOutlined />}
        size="medium"
      >
        Logout
      </Button>

      <h1
        className="splash-text"
        style={{ fontWeight: "bolder", fontSize: "60px" }}
      >
        Minsky
      </h1>
      <h3 className="splash-subheading" style={{ fontSize: "20px" }}>
      {createdOrganisations.length === 0 && invitedOrganisations.length === 0 ? "" : "Choose an Organization!"}
      </h3>
      {isLoading && <Spin />}
      {!isLoading && (createdOrganisations.length === 0 && invitedOrganisations.length === 0) && <h4 className="splash-subheading" style={{ fontSize: "15px" }}>No Organizations Found !</h4>}
      <Formik
        initialValues={{
          selectedOrgId: "",
        }}
        validationSchema={Yup.object({
          selectedOrgId: Yup.string().required("Please select an organization"),
        })}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="organisations-container">
            {/* Created Organizations */}
            {createdOrganisations.length === 0 && invitedOrganisations === false ? (
              <div>No Organizations Found</div>
            ) : (
              <>
                {createdOrganisations.map((org) => (
                  <Field
                    key={`created_${org.organisation_id}`}
                    name={`selectedOrgId_${org.organisation_id}`}
                  >
                    {({ field }) => (
                      <Card
                        key={org.organisation_id}
                        className={`org-card ${
                          selectedOrg &&
                          selectedOrg.organisation_id === org.organisation_id
                            ? "selected-card"
                            : ""
                        }`}
                        onClick={() => {
                          field.onChange(org.organisation_id);
                          handleCardClick(org);
                        }}
                      >
                        <div className="avatar-wrapper">
                          <img
                            className="avatar-logo"
                            src={Minsky}
                            alt="Avatar Logo"
                          />
                        </div>
                        <span
                          className={`org-title ${
                            selectedOrg &&
                            selectedOrg.organisation_id === org.organisation_id
                              ? "selected-title"
                              : ""
                          }`}
                        >
                          <strong>{toCamelCase(org.name)}</strong>
                        </span>
                      </Card>
                    )}
                  </Field>
                ))}
                {/* Invited Organizations */}
                {invitedOrganisations.map((org) => (
                  <Field key={`invited_${org.id}`} name={`selectedOrgId_${org.id}`}>
                    {({ field }) => (
                      <Card
                        key={org.organisation_id}
                        className={`org-card ${
                          selectedOrg &&
                          selectedOrg.organisation_id === org.organisation_id
                            ? "selected-card"
                            : ""
                        }`}
                        onClick={() => {
                          field.onChange(org.organisation_id);
                          handleCardClick(org);
                        }}
                      >
                        <div className="avatar-wrapper">
                          <img
                            className="avatar-logo"
                            src={Minsky}
                            alt="Avatar Logo"
                          />
                        </div>
                        <span
                          className={`org-title ${
                            selectedOrg &&
                            selectedOrg.organisation_id === org.organisation_id
                              ? "selected-title"
                              : ""
                          }`}
                        >
                          <strong>{toCamelCase(org.name)}</strong>
                        </span>
                      </Card>
                    )}
                  </Field>
                ))}
              </>
            )}
          </div>

          <div className="btn-container">
            {/* <Button
              type="primary"
              htmlType="submit"
              disabled={!selectedOrg}
              onClick={() => handleSubmit(selectedOrg)}
              style={{
                backgroundColor: selectedOrg ? "" : "gray",
                borderColor: selectedOrg ? "" : "gray",
                color: selectedOrg ? "" : "white",
              }}
           >
              Go Forward
            </Button> */}
            <Button
              type="primary"
              onClick={handleCreateOrg}
              style={{ background: "white", color: "black" }}
            >
              Create Organization
            </Button>
            <CreateOrgModal
              isModalVisible={isOrganisationModalVisible}
              setIsModalVisible={setIsOrganisationModalVisible}
            />
          </div>
        </Form>
      </Formik>
      {isNavigating && <div className="transition-overlay"></div>}
    </div>
  );
};

export default SelectOrgSplash;
