import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate} from "react-router-dom";
import {
  MenuUnfoldOutlined,
  DownOutlined,
  UpOutlined,
  EditOutlined

} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAssistants,
  fetchAssistantChats,
  fetchAssistantChatHistory,
  fetchUserAssistants,
} from "../../../slices/assistantSlice";
import { CloudUploadOutlined } from "@ant-design/icons";
// import { Menu } from "antd";

import "./SideNavBar.css";

const SideNavBar = () => {


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [collapsed, setCollapsed] = useState(false);
  const [assistancCollapsed, setIsAssistantCollapsed] = useState(false);
  const [assistantId, setAssistantId] = useState(null);

  const [chatsCollapsed, setChatsCollapsed] = useState(false);
  const [darkMode] = useState(false);

  const assistants = useSelector((state) => state.assistant.assistants);
  const assistantChats = useSelector((state) => state.assistant.assistantChats);

  const storedOrganisationId = localStorage.getItem("selectedOrgIdFromSplash");
  const userRole =
    localStorage.getItem("selectedOrgUserRolesFromSplash") === "user";

  useEffect(() => {
    const uuidPattern =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

    if (id && uuidPattern.test(id)) {
      dispatch(fetchAssistantChats({ assistantId: id }));
      setAssistantId(id);
    }
  }, [dispatch, id]);

  useEffect(() => {
    const fetchData = async () => {
      if (storedOrganisationId !== undefined) {
        if (userRole) {
          await dispatch(
            fetchUserAssistants({ organisationId: storedOrganisationId })
          );
        } else {
          await dispatch(
            fetchAssistants({ organisationId: storedOrganisationId })
          );
        }
      }
    };

    fetchData();
  }, [dispatch, storedOrganisationId, userRole]);

  const userAssistants = useSelector((state) => state.assistant.userAssistants);

  const toggleAssistantCollapse = () => {
    setIsAssistantCollapsed(!assistancCollapsed);
  };

  const toggleShowMoreChats = () => {
    setChatsCollapsed(!chatsCollapsed);
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const location = useLocation();

  const handleDashboardClick = () => {
    // console.log("Dashboard clicked");
    localStorage.setItem("ChatId", "");
    // console.log("Stored Chat ID in localStorage after clearing:", localStorage.getItem("ChatId"));
  };

  const handleAssistantChange = (assistantId) => {
    // console.log("Assistant changed to:", assistantId);
    localStorage.setItem("ChatId", ""); // Clear the ChatId from localStorage
    // console.log("Stored Chat ID in localStorage after clearing:", localStorage.getItem("ChatId"));
    setAssistantId(assistantId);
  };

  const handleChatClick = (chatId) => {
    // console.log("Chat clicked in handleChatClicked:", chatId);
    localStorage.setItem("ChatId", chatId);
    // console.log("Stored Chat ID in handleChatClick LocalStorage:", localStorage.getItem("ChatId"));
    dispatch(fetchAssistantChatHistory({ assistantChatId: chatId }));
  };


  const handleNewChatClick = (assistantId) => {
    localStorage.setItem("ChatId", "");
    setAssistantId(assistantId);
    if (assistantId) {
      navigate(`/assistant/${assistantId}`);
    }
  };

  return (
    <div
      className={`side-nav-bar ${collapsed ? "collapsed" : ""} ${
        darkMode ? "dark-mode" : ""
      }`}
    >
      <div className="collapse-button" onClick={toggleCollapse}>
        {!collapsed ? (
          <MenuUnfoldOutlined style={{ fontSize: "larger" }} />
        ) : (
          <MenuUnfoldOutlined style={{ fontSize: "larger" }} />
        )}
      </div>
      <div className="logo">
        <hr />
        <h1 style={{ fontFamily: "Major Mono Display" }}>
          {collapsed ? "M" : "Minsky"}
        </h1>
        <hr />
      </div>
      <ul className="nav-menu">
        {userRole ? (
          <>
            <li className="nav-item">
              <Link
                to="/dashboard"
                className={location.pathname === "/dashboard" ? "selected" : ""}
                onClick={handleDashboardClick}
              >
                {collapsed ? (
                  <i className="fa-solid fa-home"></i>
                ) : (
                  <>
                    <i className="fa-solid fa-home"></i>&nbsp; Dashboard
                  </>
                )}
              </Link>
            </li>
            {(assistancCollapsed
              ? userAssistants.slice(0, 1)
              : userAssistants
            ).map((assistant) => (
              <li className="nav-item" key={assistant.assistant_id}>
                <div className="assistant-item">
                  <Link
                    to={`/assistant/${assistant.assistant_id}`}
                    className={
                      location.pathname ===
                      `/assistant/${assistant.assistant_id}`
                        ? "selected"
                        : ""
                    }
                    onClick={() =>
                      handleAssistantChange(assistant.assistant_id)
                    }
                  >
                    {collapsed ? (
                      <i className="fa fa-commenting-o" aria-hidden="true"></i>
                    ) : (
                      <>
                        <i
                          className="fa fa-commenting-o"
                          aria-hidden="true"
                        ></i>
                        &nbsp; {assistant.assistant_name}
                      </>
                    )}
                  </Link>
                  {location.pathname ===
                    `/assistant/${assistant.assistant_id}` && <div></div>}
                </div>
              </li>
            ))}
            {!collapsed && assistants?.length > 1 && (
              <li className="show-less" onClick={toggleAssistantCollapse}>
                {assistancCollapsed ? "Show More" : "Show Less"}{" "}
                {assistancCollapsed ? (
                  <DownOutlined style={{ color: "white" }} />
                ) : (
                  <UpOutlined style={{ color: "white" }} />
                )}
              </li>
            )}
            {assistantId && (
              <>
                <li className="chats-section">
                  {assistantChats === null || "" ? (
                    ""
                  ) : (
                    <>
                      {" "}
                      <li className="separator"></li>
                      <span className="assistantHeadLine">Assistant Chats</span>
                    </>
                  )}
                </li>
                <li className="assistant-chats-wrapper">
                  <ul className="assistant-chats">
                    {!collapsed &&
                      (chatsCollapsed
                        ? assistantChats.slice(0, 3)
                        : assistantChats
                      ).map((chat) => (
                        <li
                          key={chat.id}
                          onClick={() => handleChatClick(chat.id)}
                        >
                          <span className="assistant-chat-name">
                            {chat.name.length > 20
                              ? `${chat.name.substring(0, 25)} ...`
                              : chat.name}
                          </span>
                        </li>
                      ))}
                  </ul>
                  {assistantChats?.length === 0 ? (
                    ""
                  ) : (
                    <div>
                      {!collapsed && assistantChats?.length > 3 && (
                        <li
                          className="show-less-chats"
                          onClick={toggleShowMoreChats}
                        >
                          {chatsCollapsed
                            ? "Show More Chats"
                            : "Show Less Chats"}
                          {chatsCollapsed ? (
                            <DownOutlined style={{ color: "white" }} />
                          ) : (
                            <UpOutlined style={{ color: "white" }} />
                          )}
                        </li>
                      )}
                    </div>
                  )}
                </li>
              </>
            )}
          </>
        ) : (
          <>
            <li className="nav-item">
              <Link
                to="/dashboard"
                className={location.pathname === "/dashboard" ? "selected" : ""}
                onClick={handleDashboardClick}
              >
                {collapsed ? (
                  <i className="fa-solid fa-home"></i>
                ) : (
                  <>
                    <i className="fa-solid fa-home"></i>&nbsp; Dashboard
                  </>
                )}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/search"
                className={location.pathname === "/search" ? "selected" : ""}
                onClick={handleDashboardClick}
              >
                {collapsed ? (
                  <i class="fa fa-search" aria-hidden="true"></i>
                ) : (
                  <>
                    <i class="fa fa-search" aria-hidden="true"></i>&nbsp; Search
                  </>
                )}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/upload"
                className={
                  location.pathname.includes("/upload") ? "selected" : ""
                }
                onClick={handleDashboardClick}
              >
                {collapsed ? (
                  <CloudUploadOutlined />
                ) : (
                  <>
                    <CloudUploadOutlined />
                    &nbsp; Upload Data
                  </>
                )}
              </Link>
            </li>

            {(assistancCollapsed ? assistants.slice(0, 1) : assistants).map(
              (assistant) => (
                <li className="nav-item" key={assistant.assistant_id}>
                  <div className="assistant-item">
                    <Link
                      to={`/assistant/${assistant.assistant_id}`}
                      className={
                        location.pathname ===
                        `/assistant/${assistant.assistant_id}`
                          ? "selected"
                          : ""
                      }
                      onClick={() =>
                        handleAssistantChange(assistant.assistant_id)
                      }
                    >
                      {collapsed ? (
                        <i
                          className="fa fa-commenting-o"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <>
                          <i
                            className="fa fa-commenting-o"
                            aria-hidden="true"
                          ></i>
                          &nbsp; {assistant.assistant_name}
                        </>
                      )}
                    </Link>
                    {location.pathname ===
                      `/assistant/${assistant.assistant_id}` && <div></div>}
                  </div>
                </li>
              )
            )}
            {!collapsed && assistants?.length > 1 && (
              <li className="show-less" onClick={toggleAssistantCollapse}>
                {assistancCollapsed ? "Show More" : "Show Less"}{" "}
                {assistancCollapsed ? (
                  <DownOutlined style={{ color: "white" }} />
                ) : (
                  <UpOutlined style={{ color: "white" }} />
                )}
              </li>
            )}

            {assistantId && (
              <>
                <li className="chats-section">
                  {assistantChats === null || "" ? (
                    ""
                  ) : (
                    <>
                      {" "}
                      <li className="separator"></li>
                      <span className="assistantHeadLine">Assistant Chats</span>
                    </>
                  )}
                </li>
                <div
                  className="new-chat-container"
                  onClick={() => handleNewChatClick(assistantId)}
                >
                  <div className="icon-label-container">
                  
                    <p className="new-chat-label">New Chat</p>
                    <EditOutlined />
                  </div>
                </div>

                <li className="assistant-chats-wrapper">
                  <ul className="assistant-chats">
                    {!collapsed &&
                      (chatsCollapsed
                        ? assistantChats.slice(0, 3)
                        : assistantChats
                      ).map((chat) => (
                        <li
                          key={chat.id}
                          onClick={() => handleChatClick(chat.id)}
                        >
                          <span className="assistant-chat-name">
                            {chat.name.length > 20
                              ? `${chat.name.substring(0, 25)} ...`
                              : chat.name}
                          </span>
                        </li>
                      ))}
                  </ul>
                  {assistantChats?.length === 0 ? (
                    ""
                  ) : (
                    <div>
                      {!collapsed && assistantChats?.length > 3 && (
                        <li
                          className="show-less-chats"
                          onClick={toggleShowMoreChats}
                        >
                          {chatsCollapsed
                            ? "Show More Chats"
                            : "Show Less Chats"}
                          {chatsCollapsed ? (
                            <DownOutlined style={{ color: "white" }} />
                          ) : (
                            <UpOutlined style={{ color: "white" }} />
                          )}
                        </li>
                      )}
                    </div>
                  )}
                </li>
              </>
            )}
          </>
        )}
      </ul>
      <div className="footer">
        <ul className="nav-menu">
          {!userRole && (
            <li className="nav-item">
              <Link
                to="/manageteam"
                className={
                  location.pathname === "/manageteam" ? "selected" : ""
                }
                onClick={handleDashboardClick}
              >
                {collapsed ? (
                  <i className="fa fa-briefcase" aria-hidden="true"></i>
                ) : (
                  <>
                    <i className="fa fa-briefcase" aria-hidden="true"></i>
                    &nbsp; Manage Team
                  </>
                )}
              </Link>
            </li>
          )}
        </ul>
        <p>Designed By Minsky</p>
      </div>
    </div>
  );
};

export default SideNavBar;
