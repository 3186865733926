import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import SideNavBar from "../../SideNavBar/SideNavBar";
import { useAuth0 } from "@auth0/auth0-react";
import "./Home.css";
import Navbar from "../../Navbar/Navbar";
import PieChart from "../Analytics/BarChart";
import Chatbot from "../MinksyChatbot/chatbot"


const Home = () => {

  const { user } = useAuth0();

  const combineNames = (givenName, familyName) => {
    return `${givenName} ${familyName}`;
  };


  


  return (
    <div>
      <SideNavBar />
      <Navbar />
      <div className="content">
        <h3 className="homeHeading">Hi !   {combineNames(user?.given_name, user?.family_name)} </h3>
        <hr className="content-space" />
        <PieChart />


        <Chatbot />
      </div>
    </div>
  );
};

export default withAuthenticationRequired(Home);
