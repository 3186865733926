import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosJWTInstance, axiosInstance } from "../Utilities/axiosInstance";
import MINSKY_URL from "../config";

const initialState = {
  totalCollections: [],
  chunkDetailsInfo: [],
  collectionStatus: "idle",
  loading: false,
  urlLoading: false,

  folderRecords: {
    fileRecords: [],
  },
};

export const fetchCollectionsInfo = createAsyncThunk(
  "collectionsInfo/fetchCollectionsInfo",
  async ({ orgaisationId }, { rejectWithValue }) => {
    try {
      const response = await axiosJWTInstance.get(
        `${MINSKY_URL.getCollectionInfo}${orgaisationId}/`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const chunkDetailsInfo = createAsyncThunk(
  "chunkDetails/chunkDetailsInfo",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosJWTInstance.get(
        `${MINSKY_URL.getChunkDetails}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createCollection = createAsyncThunk(
  "makingCollections/createCollection",
  async (postData, { rejectWithValue }) => {
    try {
      const response = await axiosJWTInstance.post(
        `${MINSKY_URL.creatingCollection}`,
        postData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadFileVectorDB = createAsyncThunk(
  "vectorUploadFile/uploadFileVectorDB",
  async ({ formData }) => {
    const response = await axiosInstance
      .post(`${MINSKY_URL.uploadFile}`, formData)
      .catch((error) => {
        return error.response;
      });

    return response.data;
  }
);

export const fetchFolderRecords = createAsyncThunk(
  "records/fetchRecords",
  async ({ folderId, status }) => {
    const response = await axiosInstance
      .get(`${MINSKY_URL.getFileRecords}${folderId}/?status=${status}`)
      .catch((error) => {
        return error.response;
      });

    return response.data;
  }
);

export const uploadUrlVectorDB = createAsyncThunk(
  "vectorUploadUrl/uploadUrlVectorDB",
  async ({ requestBody }) => {
    const response = await axiosJWTInstance
      .post(`${MINSKY_URL.uploadURLs}`, requestBody)
      .catch((error) => {
        return error.response;
      });
    return response.data;
  }
);

export const uploadDataSlice = createSlice({
  name: "uploadData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCollectionsInfo.pending, (state, action) => {
        state.collectionStatus = "pending";
        state.loading = true;
      })
      .addCase(fetchCollectionsInfo.fulfilled, (state, action) => {
        state.collectionStatus = "fulfilled";
        state.loading = false;

        const { data } = action.payload;
        state.totalCollections = data.map((collection) => ({
          id: collection.id,
          db_collection: collection.db_collection,
        }));
      })
      .addCase(fetchCollectionsInfo.rejected, (state, action) => {
        state.collectionStatus = "failed";
        state.loading = false;
      })
      //-----------------------------------------------------------------------------------------

      .addCase(chunkDetailsInfo.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(chunkDetailsInfo.fulfilled, (state, action) => {
        state.chunkDetailsInfo = action.payload.map(([size, details]) => size);
        state.status = "fulfilled";
      })
      .addCase(chunkDetailsInfo.rejected, (state, action) => {
        state.status = "failed";
      })

      //-----------------------------------------------------------------------------------------

      .addCase(createCollection.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(createCollection.fulfilled, (state, action) => {
        state.status = "fulfilled";
      })
      .addCase(createCollection.rejected, (state, action) => {
        state.status = "failed";
      })
      //-----------------------------------------------------------------------------------------

      .addCase(uploadUrlVectorDB.pending, (state, action) => {
        state.status = "pending";
        state.urlLoading = true;
      })
      .addCase(uploadUrlVectorDB.fulfilled, (state, action) => {
        state.urlLoading = false;
      })
      .addCase(uploadUrlVectorDB.rejected, (state, action) => {
        state.status = "failed";
        state.urlLoading = false;
      })

      .addCase(fetchFolderRecords.pending, (state, action) => {
        state.status = "pending";
        state.folderRecords.loading = true;
      })
      .addCase(fetchFolderRecords.fulfilled, (state, action) => {
        state.folderRecords.fileRecords = action?.payload;
        state.folderRecords.loading = false;
      })
      .addCase(fetchFolderRecords.rejected, (state, action) => {
        state.status = "failed";
        state.folderRecords.loading = false;
      });
  },
});

export const uploadDataReducer = uploadDataSlice.reducer;
