import React, { useState, useEffect } from "react";
import { Card, Button, Modal,Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import SideNavBar from "../../SideNavBar/SideNavBar";
import Navbar from "../../Navbar/Navbar";
import CreateFolderModal from "./Add KnowledgeBase/CreateFolderModal";
import { fetchCollectionsInfo } from "../../../../slices/uploadDataSlice";
import { useDispatch , useSelector} from "react-redux";
import "./UploadData.css";

const UploadData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isWelcomeState, setIsWelcomeState] = useState(false);


  const fetchedCollectionsInfo = useSelector(
    (state) => state.uploadData.totalCollections
  );
  const loading = useSelector(
    (state) => state.uploadData.loading
  );

  const fetchStatus = useSelector(
    (state) => state.uploadData.collectionStatus);

  // console.log(fetchStatus,"status")

  const storedOrganisationId = localStorage.getItem("selectedOrgIdFromSplash");

  useEffect(() => {
    dispatch(fetchCollectionsInfo({ orgaisationId: storedOrganisationId }));
  }, [dispatch, storedOrganisationId]);


  useEffect(() => {
    if (fetchStatus === "fulfilled" && fetchedCollectionsInfo.length === 0 && !modalVisible) {
      setIsWelcomeState(true);
    } else {
      setIsWelcomeState(false);
    }
  }, [fetchStatus, fetchedCollectionsInfo.length, modalVisible]);
  



  const handleFolderClick = (folder) => {
    setSelectedFolder(folder.id);
    navigate(`/upload/dataset/${folder.id}/${folder.db_collection}`);
  };
  



  return (
    <div>
      <SideNavBar />
      <Navbar />
      <div className="content">
        {!selectedFolder && (
          <>
          <h3 className="homeHeading" >Knowledge Base</h3>
            <div className="button-container">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setModalVisible(true)}
              >
                Create Knowledge Base
              </Button>
            </div>
            {fetchStatus === "fulfilled" && (

            <span>{fetchedCollectionsInfo.length === 0 ? "Please create a Knowledge Base first!" :"Which database are we going to use today?"}</span>
            )}
            <hr className="content-space" />
            <div className="folders-container" style={{display:"flex"}}>
            {loading ? (
                <div className="spinner-container">
                <Spin size="medium" />
              </div>

              ) : (

            fetchedCollectionsInfo.map((folder, index) => (
              // <Card
              //   key={index}
              //   style={{ width: 350, margin: "0 10px", background: "#E6E6E6", height:145 }}
              //   className="card-folder"
              //   actions={[
              //     // <Dropdown
              //     //   overlay={menu}
              //     //   trigger={["click"]}
              //     //   placement="topRight"
              //     // >
              //     //   <Button type="text" icon={<EllipsisOutlined />} />
              //     // </Dropdown>,
              //   ]}
              //   onClick={() => handleFolderClick(folder)}
              // >
              //   <Card.Meta
              //     title={folder.db_collection}
              //     // description={
              //     //   <div style={{padding:"20px"}}>
              //     //     Total Documents: {collection.totalDocuments} <br />
              //     //     Date of Creation: {collection.creationDate}
              //     //   </div>
              //     // }
              //   />
              // </Card>
                              <Card
                              key={index}
                              className="card-folder"
                              onClick={() => handleFolderClick(folder)}
                            >
                              <div className="card-folder-content">
                                <p>{folder.db_collection}</p>
                              </div>
                            </Card>

                             
            ))
          )}
            </div>
          </>
        )}

        <Modal
          title="Welcome to our knowledge base"
          visible={isWelcomeState}
          footer={[
            <Button
              key="ok"
              type="primary"
              onClick={() => setIsWelcomeState(false)}
            >
              OK
            </Button>,
          ]}
          onCancel={() => setIsWelcomeState(false)}
        >
          <p>Please create a Knowledge Base to get started!</p>
        </Modal>

        <CreateFolderModal
          visible={modalVisible}
          setModalVisible={setModalVisible}
          onCancel={() => setModalVisible(false)}
        />
      </div>
    </div>
  );
};

export default UploadData;
