import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../Utilities/axiosInstance";
import MINSKY_URL from "../config";

const initialState = {
    rolesResponse: {
        roles: {
            id: "",
            name: "",
            description: "",
        },
        token: {
            access_token: "",
            expiry_in: "",
        }, 
    },
    UserRoleAndItsOrg: {
        name: "",
        invited_organisation: [],
        created_organisation:[],
        token: {
            access_token: "",
            expiry_in: "",
        },
        loading: false
    }
};

export const userInfoAndToken = createAsyncThunk(
    'userInfoAndToken/userInfoAndToken',
    async () => {
        try {
            const response = await axiosInstance.get(`${MINSKY_URL.getUserInfoandToken}/`);
            return response.data;
        } catch (error) {
            throw error; 
        }
    }
);

export const getUserRoleAndItsOrg = createAsyncThunk(
    'userRoleAndItsOrg/userRoleAndItsOrg',
    async () => {
        try {
            const response = await axiosInstance.get(`${MINSKY_URL.getUserRoleAndItsOrg}`);
            return response.data;
        } catch (error) {
            throw error; 
        }
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(userInfoAndToken.pending, (state, action) => {
                state.status = "pending";
            })
            .addCase(userInfoAndToken.fulfilled, (state, action) => {
                state.rolesResponse = action.payload;
            })
            .addCase(userInfoAndToken.rejected, (state, action) => {
                state.status = "failed";
            })
            .addCase(getUserRoleAndItsOrg.pending, (state, action) => {
                state.status = "pending";
                state.UserRoleAndItsOrg.loading = true;
            })
            .addCase(getUserRoleAndItsOrg.fulfilled, (state, action) => {
                const { name, invited_organisation, created_organisation } = action.payload;

                state.UserRoleAndItsOrg = {
                    ...state.UserRoleAndItsOrg,
                    name: name,
                    invited_organisation: invited_organisation.map(org => ({
                        organisation_id: org.organisation_id,
                        name: org.name,
                        logo_url: org.branding?.logo_url,
                        user_roles: org.user_roles 
                    })),
                    created_organisation: created_organisation.map(org => ({
                        organisation_id: org.organisation_id,
                        name: org.name,
                        logo_url: org.branding?.logo_url 
                    })),
                    loading: false 
                };
            })
            .addCase(getUserRoleAndItsOrg.rejected, (state, action) => {
                state.status = "failed";
                state.UserRoleAndItsOrg.loading = false; 
            });
    },
});

export const authReducer = authSlice.reducer;
