import React, { useState, useEffect } from "react";
import { Table, Button, Breadcrumb, Spin } from "antd";
import SideNavBar from "../../../SideNavBar/SideNavBar";
import Navbar from "../../../Navbar/Navbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchFolderRecords } from "../../../../../slices/uploadDataSlice";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import "./Dataset.css";

const Dataset = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [status, setStatus] = useState("Completed");

  const { id: folderId, title: folderTitle } = params;

  const { fileRecords, loading } = useSelector(
    (state) => state.uploadData.folderRecords
  );

  useEffect(() => {
    if (status) {
      dispatch(
        fetchFolderRecords({
          folderId: folderId,
          status: status === "Completed" ? "Completed" : status,
        })
      );
    }
  }, [folderId, status, dispatch]);

  const columns = [
    {
      title: "Source Name",
      dataIndex: "source_name",
      key: "source_name",
      render: (text) => <ReactMarkdown>{text}</ReactMarkdown>
    },
    {
      title: "File Type",
      dataIndex: "file_type",
      key: "file_type",
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Source Path",
      dataIndex: "source_path",
      key: "source_path",
      render: (record, rowData) => {
        if (rowData.file_type === "url") {
          return null; 
        }
        return (
          <Button
            type="primary"
            size="small mt-5"
            onClick={() => handleDownload(record)}
          >
            Download
          </Button>
        );
      },
    },
  ];

  const handleDownload = (record) => {
    const url = record;
    window.open(url, "_blank");
  };

  const handleStatusChange = (value) => {
    setStatus(value);
  };

  const handleUploadFile = () => {
    navigate(`/upload/dataset/${folderId}/${folderTitle}/uploadfile`);
  };

  return (
    <div>
      <SideNavBar />
      <Navbar />
      <div className="content">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/upload">Knowledge Base</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active={!!folderTitle}>
            {folderTitle || "Dataset"}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ marginTop: "50px" }}>
          <div>
            <h3 className="homeHeading">Dataset</h3>
            <span>
              Hey, don't forget to adjust the chunk after adding the dataset! 😉
            
            </span>
            <div className="d-flex justify-content-between align-items-center">
              <div className="knowledgeNavcrumb">
                <ul className="nav nav-tabs approv">
                  <li className="statusTabs">
                    <a
                      href={() => false}
                      className={
                        "nav-link " +
                        (status === "Completed" ? "activepaylink" : "inactive")
                      }
                      onClick={() => handleStatusChange("Completed")}
                    >
                      Completed
                    </a>
                  </li>
                  <li className="statusTabs">
                    <a
                      href={() => false}
                      className={
                        "nav-link " +
                        (status === "Pending" ? "activepaylink" : "inactive")
                      }
                      onClick={() => handleStatusChange("Pending")}
                    >
                      Pending
                    </a>
                  </li>
                  <li className="statusTabs">
                    <a
                      href={() => false}
                      className={
                        "nav-link " +
                        (status === "Failed" ? "activepaylink" : "inactive")
                      }
                      onClick={() => handleStatusChange("Failed")}
                    >
                      Failed
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "23px",
              }}
            >
              <Button type="primary" onClick={handleUploadFile}>
                Upload
              </Button>
            </div>
          </div>
          {loading ? (
            <div
              style={{ marginLeft: "450px", marginTop: "70px", height: 280 }}
            >
              <Spin />
            </div>
          ) : (
            <Table columns={columns} dataSource={fileRecords?.results} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dataset;
