import React, { useState } from 'react';

const MessageParser = ({ children, actions }) => {
  const [askedForCollection, setAskedForCollection] = useState(false);
  const [collectionSelected, setCollectionSelected] = useState(false);

  const parse = (message) => {
    if (collectionSelected) {
      // If a collection has been selected, trigger handleDefault for any other message
      actions.handleDefault(message);
    } else if (askedForCollection) {
      // If already asked for collection, proceed with handling the message as usual
      if (message.startsWith('select')) {
        const collectionName = message.split(' ')[1]; 
        actions.handleCollectionSelection(collectionName); 
      } else {
        actions.handleDefault(message);
      }
    } else {
   
      if (message.includes('hello')) {
        actions.handleHello();
      } else {
        
        actions.handleHello();
        setAskedForCollection(true);
      }
    }
  };

  const handleCollectionClick = (collectionName) => {
    actions.handleCollectionSelection(collectionName);
    setCollectionSelected(true); // Set flag to indicate that a collection has been selected
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
          handleCollectionClick, 
        });
      })}
    </div>
  );
};

export default MessageParser;
