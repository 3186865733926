import React, { useState } from 'react';
import { Chatbot } from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import ActionProvider from './ActionProvider';
import MessageParser from './MessageParser';
import config from "./chatbotConfig"
import Minksy from "../../../../assets/Images/favicon.png"
import './chatbot.css';

const ChatbotComponent = () => {
  const [showChatbot, setShowChatbot] = useState(false);
  const [isIconClicked, setIsIconClicked] = useState(false);
  const [message, setMessage] = useState('');

  const handleChatbotToggle = () => {
    setShowChatbot(prevState => !prevState);
    setIsIconClicked(true);
    setTimeout(() => {
      setIsIconClicked(false);
    }, 300);
  };

  const handleOnSubmit = () => {
    if (message.trim() !== '') {
      console.log("User's message:", message);
      
      setMessage('');
    }
  };

  const validateInput = (input) => {
  
    if (input.trim() === '') {
      return false; 
    }
    return true; 
  };



  return (
    <div className="chatbot-container">
      {showChatbot && (
        <div>
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
            onSubmit={handleOnSubmit}
            headerText='Chat with Minksy'
            validator={validateInput}

          />
        </div>
      )}
      <div
        className={`chatbot-icon ${isIconClicked ? 'clicked' : ''}`}
        onClick={handleChatbotToggle}
      >
        <img src={Minksy} alt="Minksy" />
      </div>
   
    </div>
  );
};

export default ChatbotComponent;
