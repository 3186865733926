import React, { useState, useEffect } from "react";
import {
  Typography,
  Collapse,
  Row,
  Col,
  Tooltip,
  message,
  Switch,
  Select,
} from "antd";
import { CopyOutlined, LinkOutlined } from "@ant-design/icons";
import axios from "axios";
import SearchInput from "./SearchInput";
import MINSKY_URL from "../../../../config";
import "./Search.css";
import Loader from "../../../ReusableComponents/Loader/Loader";
import ReactMarkdown from "react-markdown";
import SideNavBar from "../../SideNavBar/SideNavBar";
import Navbar from "../../Navbar/Navbar";
import { fetchCollectionsInfo } from "../../../../slices/uploadDataSlice";
import { useDispatch, useSelector } from "react-redux";

const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const Search = ({ width = "100%", height = "100%" }) => {
  const dispatch = useDispatch();
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedPanelIndex, setExpandedPanelIndex] = useState(null);
  const [showDocuments, setShowDocuments] = useState(false);
  const [selectedCollectionId, setSelectedCollectionId] = useState(null);

  const storedOrganisationId = localStorage.getItem("selectedOrgIdFromSplash");

  useEffect(() => {
    dispatch(fetchCollectionsInfo({ orgaisationId: storedOrganisationId }));
  }, [dispatch, storedOrganisationId]);

  const fetchedCollectionsInfo = useSelector(
    (state) => state.uploadData.totalCollections
  );






  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setSelectedCollectionId(value);
  };

  const handleSearch = async (query) => {
    setLoading(true);
    try {
      const response = await axios.post(`${MINSKY_URL.getUserQueryResponse}`, {
        query,
        collection: selectedCollectionId,
      });
      const data = response.data;
      setSearchResults(data);
    } catch (error) {
      console.error("Error searching:", error);
    } finally {
      setLoading(false);
    }
  };

  const splitTextIntoChunks = (text, chunkSize) => {
    const chunks = [];
    let start = 0;

    while (start < text.length) {
      chunks.push(text.substr(start, chunkSize));
      start += chunkSize;
    }

    return chunks;
  };

  const handlePanelChange = (index) => {
    setExpandedPanelIndex(index === expandedPanelIndex ? null : index);
  };

  const renderPanelHeader = (item) => {
    const words = item.content.split(" ").slice(0, 6).join(" ");
    return <>{words}</>;
  };

  const handleCopy = (content) => {
    navigator.clipboard.writeText(content);
    message.success("Copied to clipboard");
  };

  return (
    <div>
      <SideNavBar />
      <Navbar />
      <div className="content">
        <h3 className="homeHeading">Search </h3>
        <hr className="content-space" />

        <div className="collection-select">
          <Select
            defaultValue="Select Collection"
            onChange={handleChange}
            disabled={fetchedCollectionsInfo.length === 0}
            placeholder="Select Collection"
            size="large"
          >
            {fetchedCollectionsInfo.map((collections) => (
              <Option key={collections.name} value={collections.id}>
                {collections.db_collection}
              </Option>
            ))}
          </Select>
          {fetchedCollectionsInfo.length === 0 ? (
            <>
              <p style={{ color: "red", paddingTop: "10px", fontSize:"15px"}}>
                Please make a collection to preview the search bar.
              </p>
            </>
          ) : (
            ""
          )}
        </div>
        {selectedCollectionId ? (
          <>
            <SearchInput onSearch={handleSearch} />
            <div
              className="search-results"
              style={{ width: width, height: height, overflowY: "auto" }}
            >
              {loading ? (
                <Loader />
              ) : (
                searchResults.status && (
                  <div className="result-container">
                    <div className="show-documents" style={{ display: "flex" }}>
                      <Typography.Text>
                        Show Documents &nbsp; &nbsp;{" "}
                      </Typography.Text>
                      <Switch
                        checked={showDocuments}
                        onChange={(checked) => setShowDocuments(checked)}
                        size="default"
                      />
                    </div>
                    <Row
                      gutter={[16, 16]}
                      style={{ borderBottom: "1px solid black " }}
                    >
                      {/* Column for searchResults.answer */}
                      <Col xs={24} md={12}>
                        <div className="result-subcontent">
                          <Typography.Title
                            level={4}
                            style={{ marginBottom: "10px" }}
                          >
                            Search Results
                          </Typography.Title>
                          {searchResults.answer &&
                            splitTextIntoChunks(searchResults.answer, 5).map(
                              (chunk, index) => (
                                <Text key={index} style={{ fontSize: "15px" }}>
                                  {chunk}
                                </Text>
                              )
                            )}
                        </div>
                      </Col>
                      {/* Column for searchResults.sources */}
                      <Col xs={24} md={12}>
                        <div className="result-links">
                          <Typography.Title
                            level={4}
                            style={{ marginBottom: "10px" }}
                          >
                            Related Links
                          </Typography.Title>
                          {searchResults.sources &&
                            searchResults.sources.map((source, index) => (
                              <div key={index}>
                                <a
                                  href={source.source}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <LinkOutlined
                                    style={{
                                      marginRight: "5px",
                                      marginBottom: "13px",
                                    }}
                                  />{" "}
                                  {/* Icon */}
                                  {source.title}
                                </a>
                              </div>
                            ))}
                        </div>
                      </Col>
                    </Row>
                    {showDocuments &&
                      searchResults.status &&
                      searchResults.documents?.length > 0 && (
                        <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                          {searchResults.documents.map((item, index) => (
                            <Col key={index} xs={24} sm={12} md={8} lg={6}>
                              <Collapse
                                activeKey={
                                  expandedPanelIndex === index
                                    ? String(index)
                                    : null
                                }
                                onChange={() => handlePanelChange(index)}
                                bordered={false}
                              >
                                <Panel
                                  header={
                                    <>
                                      {expandedPanelIndex === index
                                        ? item.title
                                        : renderPanelHeader(item)}
                                      <Tooltip title="Copy to clipboard">
                                        <CopyOutlined
                                          style={{
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                            color: "green",
                                          }}
                                          onClick={() =>
                                            handleCopy(item.content)
                                          }
                                        />
                                      </Tooltip>
                                    </>
                                  }
                                  key={index}
                                  style={{
                                    borderRadius: 8,
                                    background: "#f5f5f5",
                                    transition: "background-color 0.3s",
                                  }}
                                  className={
                                    expandedPanelIndex === index
                                      ? "expanded-panel"
                                      : ""
                                  }
                                >
                                  <ReactMarkdown>{item.content}</ReactMarkdown>
                                </Panel>
                              </Collapse>
                            </Col>
                          ))}
                        </Row>
                      )}
                  </div>
                )
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Search;
